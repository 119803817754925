export const _selectedProduct = {
	name: 'SME plus',
	shortDescription: 'start value has mixed support, consider using flex-start instead',
	longDescription: 'start value has mixed support, consider using flex-start instead',
	productType: 'VIRTUAL', // PHYSICAL
	productVariation: 'SIMPLE', //VARIABLE | GROUPED
	attributes: [
		{
			attributeId: '89a5e8a1-d735-4256-8893-994cd9d3c595',
			attributeName: 'Period',
			variation: [
				{
					variationId: '1f6a3862-3ec5-4ea3-e125-9891b356bbe4',
					variationName: 'Monthly',
				},
				{
					variationId: '14f61e2c-3f9c-486d-4527-3931f1c39f7e',
					variationName: 'Weekly',
				},
			],
		},
		{
			attributeId: '8254b7e8-9b6d-4917-e752-3bc8579f777e',
			attributeName: 'Bundle Category',
			variation: [
				{
					variationId: '7ccd88c9-9f5e-425d-5558-8199187b75b8',
					variationName: 'Classic',
				},
				{
					variationId: '19867ebf-e385-47b6-7f35-a2988b8ebd4a',
					variationName: 'Premium',
				},
				{
					variationId: 'e481f685-b5b3-4db5-a2a1-47121a6d6d2d',
					variationName: 'Special',
				},
				{
					variationId: 'd447d5cc-7f9b-4bfb-56d8-af9d3c86f7c4',
					variationName: 'Super',
				},
			],
		},
	],
	groupPricingBy: [
		'89a5e8a1-d735-4256-8893-994cd9d3c595',
		//   "8254b7e8-9b6d-4917-e752-3bc8579f777e",
	],
	pricing: [
		{
			Variation: ['1f6a3862-3ec5-4ea3-e125-9891b356bbe4', '7ccd88c9-9f5e-425d-5558-8199187b75b8'],
			Quantity: 20,
			Price: 12,
			Discount: 54,
			StartDate: '',
			EndDate: '',
		},
		{
			Variation: ['1f6a3862-3ec5-4ea3-e125-9891b356bbe4', '19867ebf-e385-47b6-7f35-a2988b8ebd4a'],
			Quantity: 20,
			Price: 43,
			Discount: '',
			StartDate: '',
			EndDate: '',
		},
		{
			Variation: ['14f61e2c-3f9c-486d-4527-3931f1c39f7e', '7ccd88c9-9f5e-425d-5558-8199187b75b8'],
			Quantity: 20,
			Price: 23,
			Discount: '',
			StartDate: '',
			EndDate: '',
		},
		{
			Variation: ['14f61e2c-3f9c-486d-4527-3931f1c39f7e', 'd447d5cc-7f9b-4bfb-56d8-af9d3c86f7c4'],
			Quantity: 10,
			Price: 32,
			Discount: '',
			StartDate: '',
			EndDate: '',
		},
	],
};

export const MockOrders = [
	{
		orderId: '6b4c67bd-689d-4cdf-8dda-3ce7195cae94',
		userId: '0547880014',
		orderNumber: 35658,
		cartDate: '2023-04-26T23:21:15.85856',
		productCount: 1,
		totalQuantity: 1,
		totalCost: 10,
		orderStatus: 'NOT PAID',
		productDetails: [
			{
				orderItemId: 'afbb9f81-1b2b-4590-9d80-aa53d0598cea',
				packageId: '02ec8a9b-a789-47d5-9115-504a2a3114c7',
				quantity: 1,
				discountAmount: 0,
				packageTotal: 10,
				addedDate: '2023-04-26T23:20:57.126361',
				packageName: 'Classic @ GHC 10',
				productName: 'SME PLUS',
				cost: 10,
				physical: false,
				checked: false,
			},
		],
	},
	{
		orderId: '6b4c67bd-689d-4cdf-8dda-3ce7195cae94',
		userId: '0547880014',
		orderNumber: 35658,
		cartDate: '2023-04-26T23:21:15.85856',
		productCount: 1,
		totalQuantity: 1,
		totalCost: 10,
		orderStatus: 'PAID',
		productDetails: [
			{
				orderItemId: 'afbb9f81-1b2b-4590-9d80-aa53d0598cea',
				packageId: '02ec8a9b-a789-47d5-9115-504a2a3114c7',
				quantity: 1,
				discountAmount: 0,
				packageTotal: 10,
				addedDate: '2023-04-26T23:20:57.126361',
				packageName: 'Classic @ GHC 10',
				productName: 'SME PLUS',
				cost: 10,
				physical: false,
				checked: false,
			},
		],
	},
];

export const selectedProduct = {
	productId: '6d947c00-e488-4907-917c-76c7c48b765f',
	categoryId: 'd0711ab3-a921-4195-b955-4d488b4bcb7a',
	workflowId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
	productName: 'Huawei Free Buds',
	productCode: null,
	status: 'DRAFT',
	quantity: 0,
	productDescription: 'string',
	productShortDescription: 'string',
	cost: 0,
	createdByUser: null,
	creationDate: '0001-01-01T00:00:00',
	productType: 'VIRTUAL',
	productVariation: 'VARIATION',
	groupPricingBy: ['ab89ac6a-3a56-48c0-b2c9-cc24eecd52cd'],
	lastModifiedDate: null,
	lastModifiedUser: null,
	pricings: [
		{
			pricingId: '940c82c9-cc25-4e17-bf6d-efa91a04cefb',
			variation: ['1def3938-7799-4132-b498-048d7b3c7886'],
			quantity: 10,
			cost: 1030,
			discount: 0,
			benefits: [
				{
					description: 'Purple freebuds with Wirless charging',
					value: 'GHC 1030',
				},
			],
			startDate: '2023-04-20T08:04:43.583',
			endDate: '2023-04-20T08:04:43.583',
		},
		{
			pricingId: '1864bf43-5863-4918-b306-1f6fc0a5a1c9',
			variation: ['72cf6244-041a-4b86-bf89-64df68c34e81'],
			quantity: 10,
			cost: 1000,
			discount: 0,
			benefits: [
				{
					description: 'white freebuds with Wirless charging',
					value: 'GHC 1000',
				},
			],
			startDate: '2023-04-20T08:04:43.583',
			endDate: '2023-04-20T08:04:43.583',
		},
	],
	productAttributes: [
		{
			attributeId: 'ab89ac6a-3a56-48c0-b2c9-cc24eecd52cd',
			attributeName: 'Huawei Freebuds Colour',
			createdByUser: 'SYSTEM',
			variations: [
				{
					variationName: 'White',
					variationId: 'e945b9dd-3108-487a-a6ac-6c06f8bdd898',
				},
				{
					variationName: 'black',
					variationId: '72cf6244-041a-4b86-bf89-64df68c34e81',
				},
				{
					variationName: 'Purple',
					variationId: '1def3938-7799-4132-b498-048d7b3c7886',
				},
			],
		},
	],
};

export const dynamicBundleWorkflow = {
	id: 'c2b29c8d-b399-475b-bca8-18789f228c5f',
	name: 'SME plus workflow',
	active: true,
	description: 'SME plus workflow',
	components: [
		{
			id: '60d8c5b5-b3b7-4469-b007-b1d848655ade',
			componentCategoryId: '333d2d0e-d0f9-41ff-8d4d-19c70637bb3e',
			componentCategoryName: null,
			componentName: 'Enter Amount to Buy',
			componentType: 'DYNAMIC_BUNDLE',
			description: 'Please enter amount to buy. Bundle ranges from Ghc 10 to 2000',
			orderIndex: 1,
			active: true,
			controls: [
				{
					id: '925dccbb-a341-4381-a5bb-098ea96214ac',
					componentId: '60d8c5b5-b3b7-4469-b007-b1d848655ade',
					type: 'NumberField',
					title: 'Enter Amount to',
					required: true,
					description: 'Input field for digits entry',
					displayName: 'Enter Amount to Buy',
					active: true,
					options: [
						{
							key: 'PLACEHOLDER',
							value: '',
							isBackend: false,
						},
						{
							key: 'REGEX',
							value: '/d/',
							isBackend: false,
						},
						{
							key: 'REGEX-MESSAGE',
							value: 'Only digits allowed',
							isBackend: false,
						},
						{
							optionId: '56bef871-ffcd-45ea-ae9f-1ed909dabd3a',
							controlId: '5a25dbfa-b162-42db-9337-34d77b76a71c',
							key: 'MIN-VALUE',
							value: '10',
							isBackend: false,
							backendValidation: false,
						},
						{
							optionId: '56bef871-ffcd-45ea-ae9f-1ed909dabd3a',
							controlId: '5a25dbfa-b162-42db-9337-34d77b76a71c',
							key: 'MAX-VALUE',
							value: '2000',
							isBackend: false,
							backendValidation: false,
						},
					],
				},
				{
					id: '5a25dbfa-b162-42db-9337-34d77b76a71c',
					componentId: '60d8c5b5-b3b7-4469-b007-b1d848655ade',
					type: 'DataSlider',
					title: 'Data Slider',
					required: true,
					description: 'Input range field for selecting data bundle',
					displayName: 'Data',
					active: true,
					options: [
						{
							optionId: '1260882d-25a8-4bd7-9808-14c7b8af2398',
							controlId: '5a25dbfa-b162-42db-9337-34d77b76a71c',
							key: 'BINARY_PREFIX',
							value: ['MB', 'GB', 'TB'],
							isBackend: false,
							backendValidation: false,
						},
						{
							optionId: '56bef871-ffcd-45ea-ae9f-1ed909dabd3a',
							controlId: '5a25dbfa-b162-42db-9337-34d77b76a71c',
							key: 'MIN-VALUE',
							value: '1',
							isBackend: false,
							backendValidation: false,
						},
						{
							optionId: '56bef871-ffcd-45ea-ae9f-1ed909dabd3a',
							controlId: '5a25dbfa-b162-42db-9337-34d77b76a71c',
							key: 'MAX-VALUE',
							value: '100',
							isBackend: false,
							backendValidation: false,
						},
					],
				},
				{
					id: '5a25dbfa-b162-42db-9337-34d77b76a71c',
					componentId: '60d8c5b5-b3b7-4469-b007-b1d848655ade',
					type: 'VoiceSlider',
					title: 'Voice Slider',
					required: true,
					description: 'Input range field for selecting voice bundles',
					displayName: 'Voice',
					active: true,
					options: [
						{
							optionId: '1260882d-25a8-4bd7-9808-14c7b8af2398',
							controlId: '5a25dbfa-b162-42db-9337-34d77b76a71c',
							key: 'BINARY_PREFIX',
							value: ['Mins'],
							isBackend: false,
							backendValidation: false,
						},
						{
							optionId: '56bef871-ffcd-45ea-ae9f-1ed909dabd3a',
							controlId: '5a25dbfa-b162-42db-9337-34d77b76a71c',
							key: 'MIN-VALUE',
							value: '1',
							isBackend: false,
							backendValidation: false,
						},
						{
							optionId: '56bef871-ffcd-45ea-ae9f-1ed909dabd3a',
							controlId: '5a25dbfa-b162-42db-9337-34d77b76a71c',
							key: 'MAX-VALUE',
							value: '100',
							isBackend: false,
							backendValidation: false,
						},
					],
				},
				{
					id: '5a25dbfa-b162-42db-9337-34d77b76a71c',
					componentId: '60d8c5b5-b3b7-4469-b007-b1d848655ade',
					type: 'SMSSlider',
					title: 'SMS Slider',
					required: true,
					description: 'Input range field for selecting sms bundles',
					displayName: 'Sms',
					active: true,
					options: [
						{
							optionId: '1260882d-25a8-4bd7-9808-14c7b8af2398',
							controlId: '5a25dbfa-b162-42db-9337-34d77b76a71c',
							key: 'BINARY_PREFIX',
							value: ['SMS'],
							isBackend: false,
							backendValidation: false,
						},
						{
							optionId: '56bef871-ffcd-45ea-ae9f-1ed909dabd3a',
							controlId: '5a25dbfa-b162-42db-9337-34d77b76a71c',
							key: 'MIN-VALUE',
							value: '1',
							isBackend: false,
							backendValidation: false,
						},
						{
							optionId: '56bef871-ffcd-45ea-ae9f-1ed909dabd3a',
							controlId: '5a25dbfa-b162-42db-9337-34d77b76a71c',
							key: 'MAX-VALUE',
							value: '100',
							isBackend: false,
							backendValidation: false,
						},
					],
				},
			],
			createdBy: null,
			dateCreated: '0001-01-01T00:00:00',
		},
		{
			id: '60d8c5b5-b3b7-4469-b007-b1d848655ade',
			componentCategoryId: '333d2d0e-d0f9-41ff-8d4d-19c70637bb3e',
			componentCategoryName: null,
			componentName: 'Select Beneficiary',
			componentType: 'DYNAMIC_BUNDLE',
			description: 'Please select package beneficiary',
			orderIndex: 1,
			active: true,
			controls: [
				{
					id: '925dccbb-a341-4381-a5bb-098ea96214ac',
					componentId: '60d8c5b5-b3b7-4469-b007-b1d848655ade',
					type: 'RadioButtonLabel',
					title: 'Radio Button Label',
					required: true,
					description: 'Input field for digits entry',
					displayName: 'Radio Button Label',
					active: true,
					options: [
						{
							key: 'LABEL',
							value: 'Individual',
							isBackend: false,
						},
					],
				},
				{
					id: '925dccbb-a341-4381-a5bb-098ea96214ac',
					componentId: '60d8c5b5-b3b7-4469-b007-b1d848655ade',
					type: 'RadioButtonLabel',
					title: 'Radio Button Label',
					required: true,
					description: 'Input field for digits entry',
					displayName: 'Radio Button Label',
					active: true,
					options: [
						{
							key: 'LABEL',
							value: 'Group',
							isBackend: false,
						},
					],
				},
			],
			createdBy: null,
			dateCreated: '0001-01-01T00:00:00',
		},
	],
};
