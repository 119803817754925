import {Asterisk} from '../../asterisk/Asterisk';
import {useSelector} from 'react-redux';

function RadioButtonsEntry({data, index}) {
	const separator = ';;';
	const items = () => data?.options?.filter((x) => x.key == 'ITEMS')[0].value.split(separator);
	const isVertical = () => data?.options?.find((x) => x.key == 'DIRECTION').value == 'vertical';
	const isFieldRequired = data?.required;
	const fieldErrorMessages = useSelector((state) => state.product.fieldErrorMessages);
	const hasErrorMessage = fieldErrorMessages.some((item) => item.id === data?.id);

	const radioStyles = {
		fontSize: '12px',
		color: 'red !important',
		width: '288px',
		textAlign: 'left',
	};

	const radioField_containerStyles = {
		marginRight: '183px',
	};

	return (
		<>
			<div className='flexColumn requestEntry_field_container py-5' style={radioStyles}>
				<div className={'error-placeholder error-comp-' + data?.componentId}></div>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? (
					<small className={data?.required ? 'required requestEntry_label textCenter' : 'requestEntry_label textCenter'}>
						{data?.displayName} {isFieldRequired && <Asterisk />}
					</small>
				) : (
					''
				)}
				{isVertical() ? (
					<div className='radioField_container' style={radioField_containerStyles}>
						{items().map((item, item_key) => (
							<div key={item_key}>
								<input id={'rb_' + data?.componentId + '_' + item_key} name={`response[${index}][value]`} type='radio' value={item} />{' '}
								<label style={{marginRight: '10px'}} htmlFor={'rb_' + data?.componentId + '_' + item_key}>
									{item}
								</label>
							</div>
						))}
					</div>
				) : (
					<>
						{items().map((item, item_key) => (
							<label key={item_key} style={{marginRight: '10px'}} htmlFor={'rb_' + data?.componentId + '_' + item_key}>
								<input id={'rb_' + data?.componentId + '_' + item_key} name={`response[${index}][value]`} type='radio' value={item} /> {item}
							</label>
						))}
						<br></br>
					</>
				)}{' '}
				<p className='text-red-600 text-[12px] mb-5'>{hasErrorMessage && `${data?.displayName} is required`}</p>

				
				<input type='hidden' name={`response[${index}][meta]`} />
				<input type='hidden' name={`response[${index}][controlName]`} value={data?.displayName} />
				<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
				<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
			</div>
		</>
	);
}

export default RadioButtonsEntry;
