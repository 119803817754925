import React, {useState} from 'react';
import {GroupIcon} from '../../../icons/GroupIcon';
import {ThreedotsIcon} from '../../../icons/ThreedotsIcon';
import {Modal} from '../../../modal/_component';
import GroupMembers from './GroupMembers';
import CustomDropdownMenu from '../../../customDropdown/_component';
import {useQuery} from 'react-query';
import {GetGroupsByMsisdn} from '../../../../../api/workflow.service';
import {getItem} from '../../../../../api/storage.service';
import {useDispatch, useSelector} from 'react-redux';
import {setPurchaseForGroup, setSelectedGroup, setSelectedMsisdn} from '../../../../../redux/product/productSlice';

const GroupComponent = () => {
	const [showModal, setShowModal] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState();
	const dispatch = useDispatch();
	const selectedMsisdn = useSelector((state) => state?.product?.selectedMsisdn);
	let memberForm;

	const groupQuery = useQuery({
		retry: (count, err) => count < 3,
		staleTime: Infinity,
		queryKey: ['product'],
		queryFn: () => GetGroupsByMsisdn(getItem('u_msisdn')).then((res) => res.data),
		// onSuccess: (data) => onProductFetchSuccess(data),
	});

	const handleGroupSelect = (e, group) => {
		if (e.target.checked) {
			let phoneNumbers = group?.groupMembers?.map((x) => {
				return x?.msisdn;
			});
			// if (!selectedMsisdn.includes([...phoneNumbers])) {
			// 	dispatch(setSelectedMsisdn([...selectedMsisdn, ...phoneNumbers]));
			// }
			dispatch(setSelectedMsisdn([...phoneNumbers]));
			dispatch(
				setPurchaseForGroup({
					GroupPurchase: true,
					GroupId: group?.id,
					GroupMembers: [...phoneNumbers],
				}),
			);
		}
		// else {
		// 	dispatch(setSelectedMsisdn([...selectedMsisdn?.filter((x) => x.id !== group?.id)]));
		// }
	};

	return (
		<>
			{groupQuery?.isFetching ? (
				<p className='text-sm mb-2'>Loading ...</p>
			) : (
				<>
					<p className='!font-extrabold text-sm mb-2'>Available Groups</p>
					{groupQuery?.data?.data?.length > 0 ? (
						groupQuery?.data?.data?.map((group, idx) => (
							<>
								<div className='flex items-center justify-between gap-2 mb-2 cursor-pointer' key={idx}>
									<div className='flex items-center gap-2'>
										<div className='bg-[#fc0] rounded-full p-1'>
											<GroupIcon className='w-5 h-5' />
										</div>
										<p className='text-sm'>{group?.groupName}</p>
									</div>
									<div className='flex items-center'>
										<CustomDropdownMenu type='group' data={group} refetch={groupQuery.refetch} setShowModal={setShowModal} />
										<input type='radio' onChange={(e) => handleGroupSelect(e, group)} name='groups' className='!mt-2 accent-black' />
									</div>
								</div>
							</>
						))
					) : (
						<>
							<p className='text-xs text-gray-500'>Sorry, you have no groups yet</p>
						</>
					)}
					<div
						className='flex items-center gap-1 pt-3 cursor-pointer'
						onClick={() => {
							setShowModal(true);
							memberForm.clearGroupForm();
						}}
					>
						<GroupIcon className='w-5 h-5' />
						<p className='hover:font-bold underline text-[12px]'>Add Group</p>
					</div>
				</>
			)}

			<Modal open={showModal} close={setShowModal}>
				<GroupMembers isFetching={groupQuery?.isFetching} refetch={groupQuery.refetch} onInit={(e) => (memberForm = e)} setShowModal={setShowModal} />
			</Modal>
		</>
	);
};

export default GroupComponent;
