import {getAxios, getConfig} from '../utils';

const apiAxios = () => getAxios(getConfig().apiBaseUrl.utilitiesBaseUrl);

export const uploadFile = async (payload) => await apiAxios().post('Upload/upload-image', {payload});

export const workflowUpload = async (formData) => {
   try {
       const response = await apiAxios().post('Upload/upload-image', formData, {
           headers: {
               'Content-Type': 'multipart/form-data'
           }
       });
       return response.data; // Return the response data directly
   } catch (error) {
       console.error('Upload error:', error); // Log the error for debugging
       throw error; // Re-throw the error to handle it in the caller function
   }
};
