function RadioButtonsView({data, index, value}) {
	const separator = ';;';
	const items = () => data?.options?.filter((x) => x.key == 'ITEMS')[0].value.split(separator);

	const radioStyles = {
		fontSize: '12px',
		color: 'red !important',
		width: '288px',
		textAlign: 'left',
	};

	const radioField_containerStyles = {
		marginRight: '183px',
	};

	return (
		<>
			<div className='flexColumn requestEntry_field_container' style={radioStyles}>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? <small className='requestEntry_label'>{data?.displayName}</small> : ''}
				<div className='radioField_container' style={radioField_containerStyles}>
					{items()?.map((item, item_key) => (
						<div key={item_key} style={{marginTop: '7px'}}>
							<input id={'rb_' + data?.componentId + '_' + item_key} type='radio' value={item} disabled={true} checked={value?.value === item} />{' '}
							<label style={{marginRight: '10px'}} htmlFor={'rb_' + data?.componentId + '_' + item_key}>
								{item}
							</label>
						</div>
					))}
				</div>
			</div>
		</>
	);
}

export default RadioButtonsView;
