import moment from 'moment';
import {Asterisk} from '../../asterisk/Asterisk';
import {useSelector} from 'react-redux';

function DateFieldEntry({data, index}) {
	const dateFieldStyles = {
		fontSize: '12px',
		color: '#141414c0',
	};
	const isFutureDatePrevented = () => data?.options?.filter((x) => x.key == 'PREVENT-FUTURE-DATE')[0]?.value.toString() == 'true';
	const enableTimePicker = () => data?.options?.filter((x) => x.key == 'ENABLE-TIME-PICKER')[0]?.value.toString() == 'true';
	const minAge = () => data?.options?.filter((x) => x.key == 'MIN-AGE')[0]?.value;
	const isFieldRequired = data?.required;
	const fieldErrorMessages = useSelector((state) => state.product.fieldErrorMessages);
	const hasErrorMessage = fieldErrorMessages.some((item) => item.id === data?.id);

	return (
		<>
			<div className='flexColumn requestEntry_field_container'>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? (
					<small className='requestEntry_label'>
						{data?.displayName} {isFieldRequired && <Asterisk />}
					</small>
				) : (
					''
				)}
				<div className={'error-placeholder error-comp-' + data?.componentId}></div>
				<input
					style={dateFieldStyles}
					name={`response[${index}][value]`}
					type={enableTimePicker() ? 'datetime-local' : 'date'}
					className='requestEntry_formFields text-[13px] w-full my-[10px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none '
					placeholder='placeholder'
					{...(Number(minAge()) == 0 && isFutureDatePrevented() && {max: moment().format('yyyy-MM-DD')})}
					{...(Number(minAge()) > 0 && {max: moment().subtract(Number(minAge()), 'years').format('yyyy-MM-DD')})}
				/>
				<p className='text-red-600 text-[12px] mb-5'>{hasErrorMessage && `${data?.displayName} is required`}</p>

				<input type='hidden' name={`response[${index}][meta]`} />
				<input type='hidden' name={`response[${index}][controlName]`} value={data?.displayName} />
				<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
				<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
			</div>
		</>
	);
}

export default DateFieldEntry;
