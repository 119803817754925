import React, {Children, FormEvent, useState} from 'react';

import {formToJSON} from 'axios';
import classNames from 'classnames';
import {uuid} from '../../../utils';

export const FormWizard = (prop) => {
	const [currentStage, setCurrentStage] = useState(prop.currentStage ?? 0);
	const [formData, setFormData] = useState({});
	const children = Children.toArray(prop.children);
	const nextClass = 'form-wizard-next';
	const prevClass = 'form-wizard-prev';

	const formId = prop.formId ?? 'f_' + uuid();

	const onFormSubmit = (form, submitter) => {
		let data = formToJSON(form);
		setFormData(data);

		if (submitter.includes(nextClass)) {
			if (prop?.beforeNext && !prop?.beforeNext(currentStage, data)) return false;
			return onNext();
		}

		if (submitter.includes(prevClass)) {
			return onPrev();
		}

		if (currentStage == children.length - 1) {
			if (prop?.beforeNext && !prop?.beforeNext(currentStage, data)) return false;
			if (prop?.beforeSubmit && prop?.beforeSubmit(currentStage, data)) return false;
			if (prop?.onSubmit) return prop?.onSubmit(currentStage, data);
		}
	};

	const onSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		onFormSubmit(form, e.nativeEvent.submitter.classList.toString());
	};

	const onNext = () => {
		if (currentStage >= children.length - 1) return;
		setCurrentStage(currentStage + 1);
	};

	const onPrev = () => {
		if (currentStage <= 0) return;
		setCurrentStage(currentStage - 1);
	};
	const goToStep = (n) => {
		setCurrentStage(n);
	};

	const onFormChange = (e) => {
		const data = formToJSON(e.target.form);
		setFormData({...data});
	};

	if (prop.onInit) {
		prop.onInit({
			formId,
			goToStep,
			next: () => {
				var form = document.getElementById(formId);
				onFormSubmit(form, nextClass);
			},
			prev: () => {
				var form = document.getElementById(formId);
				onFormSubmit(form, prevClass);
			},
			submit: () => {
				var form = document.getElementById(formId);
				onFormSubmit(form, '');
			},
			triggerDataChange: () => {
				var form = document.getElementById(formId);
				var event = new Event('change');
				form.dispatchEvent(event);
			},
			current: () => currentStage,
			getFormData: function () {
				const data = formToJSON(document.getElementById(formId));
				setFormData({...data});
				return {...formData};
			},
		});
	}

	return (
		<>
			<form className={prop.className} id={formId} onSubmit={onSubmit} onChange={onFormChange}>
				{children?.map((child, idx) => (
					<div className={idx == currentStage ? prop.stageVisibleCls : prop.stageHiddenCls} key={idx}>
						{prop?.enableIndicator && (
							<>
								<h2 className='w-full text-xl'>{child.props?.title}</h2>
								<FormStageIndicator length={children.length} current={idx} />
							</>
						)}
						{child}
					</div>
				))}
			</form>
		</>
	);
};

export const WizardStage = (prop) => {
	return <>{prop?.children}</>;
};

export const FormStageIndicator = ({length, current}) => {
	let sections = Array.from(Array(length).keys());
	return (
		<div className='row flex'>
			{sections.map((key, index) => (
				<div key={index} className={classNames('rounded-full mr-1 h-2 w-6 ', {'bg-gray-400': index != current && index > current}, {'bg-green-600': index < current}, {'bg-slate-300': index == current})}></div>
			))}
		</div>
	);
};
