function DropdownListView({data, index, value}) {
	const separator = ';;';
	const items = () => data?.options?.filter((x) => x.key == 'ITEMS')[0].value.split(separator);

	const selectFieldStyle = {
		width: '260px',
		fontSize: '11px',
	};

	return (
		<>
			<div className='flexColumn requestEntry_field_container'>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? <small className='requestEntry_label'>{data?.displayName}</small> : ''}
				<select disabled={true} value={value?.value} className='flexColumn requestEntry_formFields' style={selectFieldStyle}>
					<option>{data?.options.filter((x) => x.key === 'PLACEHOLDER')[0]?.value}</option>
					{items()?.map((item, i) => (
						<option value={item} key={i}>
							{item}
						</option>
					))}
				</select>
			</div>
		</>
	);
}

export default DropdownListView;
