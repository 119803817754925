import React, { useEffect, useRef, useState } from 'react';
import '../../../app.scss';
import LocationPinIcon from '../../../assets/icons/locationPin.png';
import YellowPinIcon from '../../../assets/icons/yellowpin.png';
import mapStyle from './mapStyle';
import { BsSearch } from 'react-icons/bs';
import { useAlertMessage } from '../../../hooks/useAlertMessage';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as FibreAvailableIcon } from '../../../assets/icons/fibre-available.svg';
import { ReactComponent as NoFibreCoverageIcon } from '../../../assets/icons/fibre-no-coverage.svg';
import { useQuery } from 'react-query';
import { useJsApiLoader } from '@react-google-maps/api';
import { setLocation } from '../../../redux/location/locationSlice';
import { setAppLoading } from '../../../redux/app/appSlice';
import { CheckFibreAvailability } from '../../../api/utilities.service';
import { setDisableWorkflowCta } from '../../../redux/product/productSlice';
import NotifyMe from '../notifyMe/NotifyMe';

const options = {
	libraries: ['places'],
};

const Map = ({data, index}) => {
	const dispatch = useDispatch();
	const mapKey = useSelector((state) => state?.app?.config?.mapKey);
	const placesRef = useRef();
	const autocompleteRef = useRef(null);
	const {isLoaded} = useJsApiLoader({
		googleMapsApiKey: mapKey,
		...options,
	});
	const [fibreAvailability, setFibreAvailability] = useState(false);
	const [hideMap, setHideMap] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [locationInfo, setLocationInfo] = useState(null);
	const [suggestions, setSuggestions] = useState([]);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const autocompleteServiceRef = useRef(null);
	const getCoderServiceRef = useRef(null);
	let {showErrorMessage, showSuccessMessage} = useAlertMessage();
	const showMap = data?.options?.find((x) => x.key == 'SHOW-MAP')?.value === 'true';
	const fieldErrorMessages = useSelector((state) => state.product.fieldErrorMessages);
	const hasErrorMessage = fieldErrorMessages.some((item) => item.id === data?.id);

	const [defaultCenter, setDefaultCenter] = useState({
		lat: 5.614818,
		lng: -0.205874,
	});

	const checkFibreAvailability = data?.options?.find((x) => x.key == 'CHECK-FIBRE-AVAILABILITY')?.value === 'true';
	const fibreQuery = useQuery({
		retry: (count, err) => count < 3,
		queryKey: [locationInfo?.lat, locationInfo?.lng],
		queryFn: () =>
			CheckFibreAvailability({
				latitude: defaultCenter?.lat,
				longitude: defaultCenter?.lng,
				serviceType: 0,
			}).then((res) => res.data),
		enabled: checkFibreAvailability && locationInfo ? true : false,
	});

	useEffect(() => {
		if (fibreQuery?.data?.data?.fibre) {
			setFibreAvailability(true);
		} else {
			setFibreAvailability(false);
			if (checkFibreAvailability) {
				dispatch(setDisableWorkflowCta(true));
			}
		}

		// Cleanup function to run on unmount
		return () => {
			dispatch(setDisableWorkflowCta(false)); // Set to false on unmount
		};
	}, [defaultCenter, fibreQuery?.data?.data?.fibre]);

	useEffect(() => {
		if (isLoaded) {
			autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
			getCoderServiceRef.current = new window.google.maps.Geocoder();

			var map = new window.google.maps.Map(document.getElementById('map'), {
				center: defaultCenter,
				zoom: 15,
				disableDefaultUI: true,
				gestureHandling: 'greedy',
				styles: mapStyle,
			});

			var marker = new window.google.maps.Marker({
				map,
				draggable: false,
				animation: window.google.maps.Animation.DROP,
				position: defaultCenter,
				title: 'drag to select your location',
				icon: YellowPinIcon,
			});

			marker.bindTo('position', map, 'center');

			//handle map center_changed event
			window.google.maps.event.addListener(map, 'dragend', function () {
				var coordinates = map.getCenter().toUrlValue(6);
				var lat = parseFloat(coordinates.split(',')[0]);
				var lng = parseFloat(coordinates.split(',')[1]);
				handleMarkerDragEnd(lat, lng);
			});
		}
	}, [isLoaded, defaultCenter]);

	const handleMarkerDragEnd = (lat, lng) => {
		setLocationInfo({lat, lng});
		// setDefaultCenter({lat, lng});
		dispatch(setLocation({lat, lng}));
		getAddressByLatLng(lat, lng);
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
		if (event.key === ' ' || event.key === 'Enter') {
			fetchSuggestions(placesRef.current.value);
		}
	};

	const fetchSuggestions = (input) => {
		if (autocompleteServiceRef.current && input) {
			autocompleteServiceRef.current.getPlacePredictions(
				{
					input,
					componentRestrictions: {country: 'gh'},
				},
				(predictions, status) => {
					if (status === window.google.maps.places.PlacesServiceStatus.OK) {
						setSuggestions(predictions);
						setShowSuggestions(true);
					}
				},
			);
		}
	};

	const handleSuggestionClick = (suggestion) => {
		setShowSuggestions(false);
		placesRef.current.value = suggestion.description;
		getCoderServiceRef.current.geocode(
			{
				placeId: suggestion.place_id,
			},
			function (results, status) {
				if (status === 'OK') {
					if (results[0]) {
						var lat = results[0].geometry.location.lat();
						var lng = results[0].geometry.location.lng();
						setLocationInfo({lat, lng});
						setDefaultCenter({lat, lng});
						setHideMap(false);
						dispatch(setLocation({lat, lng}));
					}
				}
			},
		);
	};

	const containerStyle = {
		width: '100%',
		height: '400px',
		display: hideMap ? 'none' : 'block',
	};

	const getMyLocation = () => {
		dispatch(setAppLoading(true));
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showPosition);
		} else {
			showErrorMessage('Geolocation is not supported by this browser', 'error');
		}
	};

	const showPosition = (position) => {
		const lat = position.coords.latitude;
		const lng = position.coords.longitude;
		dispatch(setAppLoading(false));
		setLocationInfo({lat, lng});
		setDefaultCenter({lat, lng});
		dispatch(setLocation({lat, lng}));
		getAddressByLatLng(lat, lng);
		setShowSuggestions(false);
		setHideMap(false);
	};

	if (!isLoaded) {
		return (
			<div className='grid place-items-center min-h-[80vh]'>
				<p>loading...</p>
			</div>
		);
	}

	const getAddressByLatLng = (lat, lng) => {
		getCoderServiceRef.current.geocode({location: {lat, lng}}, (results, status) => {
			if (status === 'OK') {
				if (results[0]) {
					if (placesRef.current) {
						placesRef.current.value = results[0].formatted_address;
					}
				} else {
					console.log('No results found');
				}
			} else {
				console.log('Geocoder failed due to: ' + status);
			}
		});
	};

	return (
		<>
			{/* desktop ui */}
			<div className=''>
				{checkFibreAvailability &&
					!hideMap &&
					(fibreQuery?.isFetching ? (
						<p className='text-sm text-gray-400 mt-2'>Checking ...</p>
					) : (
						<div className='flex gap-3 mt-4'>
							{fibreAvailability ? <FibreAvailableIcon /> : <NoFibreCoverageIcon />}
							<div>
								<div>
									<p className={`text-2xl mb-1 font-bold ${!fibreAvailability && 'text-[#BE1E50]'}`}>{fibreAvailability ? 'Fiber avaliable' : 'No Coverage'}</p>
								</div>
								{/* <div>{fibreAvailability ? <p className='text-xs'>This area has fibre connection</p> : <p className='text-xs'>Fiber Broadband is currently unavailable at your location</p>}</div> */}

								<div>
									{fibreAvailability ? (
										<p className='text-xs'>This area has fibre connection</p>
									) : (
										<p className='text-xs underline cursor-pointer' onClick={() => setShowModal(true)}>
											Inform me when this area is connected
										</p>
									)}
								</div>
							</div>
						</div>
					))}

				<div className=''>
					{showMap && <div style={containerStyle} className='my-4' id='map'></div>}

					{/* {!hideMap && (
						<div className='flex gap-2 mt-3 text-[13px] w-full my-[10px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none'>
							<LabelIcon /> <input className='w-full outline-none focus:outline-none border-0 bg-transparent' placeholder='Landmark, Building type, House No. etc' />
						</div>
					)} */}

					<div className='flex gap-4 mt-3 text-[13px] p-4 w-full rounded-xl border-[none] bg-[#f2f2f2] '>
						{locationInfo ? <img src={LocationPinIcon} alt='img' className='' /> : <BsSearch size={17} className='' />}
						<input
							onKeyDown={handleKeyPress}
							onChange={() => {
								setHideMap(true);
							}}
							className='outline-none border-none bg-transparent w-full focus:outline-none'
							placeholder='Search Location'
							ref={placesRef}
						/>
					</div>

					{showSuggestions && (
						<div className='map_results_container'>
							<div className='useMyLocation cursor-pointer' onClick={() => getMyLocation()}>
								Use My Location
							</div>
							{suggestions.map((suggestion) => (
								<div id='results' key={suggestion.place_id} className='map_results_container cursor-pointer' onClick={() => handleSuggestionClick(suggestion)}>
									{suggestion.description}
								</div>
							))}
						</div>
					)}
				</div>
			</div>
			<p className='text-red-600 text-[12px] mb-5 mt-2'>{hasErrorMessage && `${data?.displayName} is required`}</p>

			<input type='hidden' name={`response[${index}][value]`} value={locationInfo ? `${locationInfo?.lat},${locationInfo?.lng}` : ''} />
			<input type='hidden' name={`response[${index}][meta]`} />
			<input type='hidden' name={`response[${index}][controlName]`} value={data?.displayName} />
			<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
			<input type='hidden' name={`response[${index}][id]`} value={data?.id} />

			<NotifyMe showModal={showModal} setShowModal={setShowModal} location={placesRef?.current?.value}/>
		</>
	);
};

export default Map;
