import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {CheckMomoPaymentStatus} from '../../api/payment.service';
import {getItem} from '../../api/storage.service';
import ReturnButton from '../../components/reusable/returnButton/ReturnButton';
import {Timer} from '../../components/reusable/timer/Timer';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {setPaymentIsSuccessful, setPaymentErrorMessage} from '../../redux/product/productSlice';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';

const ConfirmPayment = () => {
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [paymentIntervalId, setPaymentIntervalId] = useState(0);
	const _pingInterval = 5000;
	const u_msisdn = getItem('u_msisdn');
	const order_id = getItem('orderID');
	let otpTimer;

	const pingPaymentStatus = () => {
		let payLoad = {
			msisdn: u_msisdn,
			orderId: order_id,
		};
		CheckMomoPaymentStatus(payLoad)
			.then((res) => {
				if (res.data.success) {
					clearInterval(paymentIntervalId);
					dispatch(setPaymentIsSuccessful(true));
					navigate('/payment-status');
					otpTimer.pulse();
				} else if (res.data.message.toLowerCase() === 'failed') {
					clearInterval(paymentIntervalId);
					dispatch(setPaymentIsSuccessful(false));
					dispatch(setPaymentErrorMessage('Payment Failed'));
					navigate('/payment-status');
					otpTimer.pulse();
				} else if (res.data.message === 'INSUFFICIENT BALANCE') {
					clearInterval(paymentIntervalId);
					dispatch(setPaymentIsSuccessful(false));
					dispatch('Insufficient Balance');
					navigate('/payment-status');
					otpTimer.pulse();
				}
			})
			.catch((err) => {
				showErrorMessage('An Error occured !', 'error');
				console.log('Error Log:', err);
			});
	};

	useEffect(() => {
		const intervalId = setInterval(() => pingPaymentStatus(), _pingInterval);
		setPaymentIntervalId(intervalId);
		otpTimer.start(3);
		return () => clearInterval(intervalId);
	}, [order_id]);

	const manualPingPaymentStatus = () => {
		clearInterval(paymentIntervalId);
		dispatch(setPaymentIsSuccessful(false));
		navigate('/payment-status');
		otpTimer.pulse();
		// let payLoad = {
		// 	msisdn: u_msisdn,
		// 	orderId: order_id,
		// };
		// CheckMomoPaymentStatus(payLoad)
		// 	.then((res) => {
		// 		if (res.data.success) {
		// 			dispatch(setPaymentIsSuccessful(true));
		// 			navigate('/payment-status');
		// 			otpTimer.pulse();
		// 		} else if (res.data.message.toLowerCase() === 'failed') {
		// 			dispatch(setPaymentIsSuccessful(false));
		// 			showErrorMessage('Payment Unsuccessful, Please try again !', 'error');
		// 		}
		// 	})
		// 	.catch((err) => {
		// 		showErrorMessage('An Error occured !', 'error');
		// 		console.log('Error Log:', err);
		// 	});
	};

	return (
		<>
			<PageTitle title='Business Hub - Awaiting Payment' />

			<div className='py-[40px] px-[20px] pb-0 bg-white min-h-[500px] '>
				<ReturnButton />
				<div className='lg:w-1/2 '>
					<h1 className='text-center text-black font-bold text-[25px] mt-5'>Waiting for payment authorization</h1>
					<p className='text-center text-[14px] mt-[10px]'>To complete the purchase, you'll receieve a prompt to enter your MoMo PIN to confirm this transaction</p>
					<Timer
						visible={true}
						id='payment-timer'
						onInit={(e) => (otpTimer = e)}
						callback={() => {
							clearInterval(paymentIntervalId);
							navigate('/payment-status');
						}}
					>
						<div className='text-sm text-center mt-12'>
							Payment Timeout in <span className='min font-bold'>00</span>:<span className='sec font-bold'>00</span> secs
						</div>
					</Timer>
					<button className='rounded-full w-full bg-[#ffcc00] p-3 font-bold mt-32 hover:bg-[#ffcc00a8]' onClick={() => manualPingPaymentStatus()}>
						Done
					</button>
				</div>
			</div>
		</>
	);
};

export default ConfirmPayment;
