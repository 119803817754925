import {useState} from 'react';

function IDValidationFieldView({data, index, value}) {
	const [selectedID, setSelectedID] = useState();
	const separator = ';;';

	const allowedIds = () => data?.options?.filter((x) => x.key == 'ALLOWED-ID')[0].value.split(separator);

	const changeIdState = (selected) => {
		setSelectedID(selected);
	};

	const selectFieldStyle = {
		width: '259px',
		fontSize: '11px',
	};

	return (
		<>
			<div className='flexColumn requestEntry_field_container'>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? <small className='requestEntry_label'>{data?.displayName}</small> : ''}
				<select disabled={true} value={value?.meta} name={`response[${index}][meta]`} required={data?.required} onChange={(e) => changeIdState(e.target.value)} style={selectFieldStyle} className='flexColumn requestEntry_formFields'>
					<option style={{color: 'lightgray'}}>Select ID Type</option>
					{allowedIds().map((item, item_key) => (
						<option key={item_key}>{item}</option>
					))}
				</select>
			</div>

			<div className='flexColumn requestEntry_field_container'>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? <small className='requestEntry_label'>{data?.displayName}</small> : ''}
				<input className='flexColumn text-[13px] w-full my-[10px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' type='text' readOnly={true} value={value?.value} />
			</div>
		</>
	);
}

export default IDValidationFieldView;
