import {toast, Toaster, ToastBar} from "react-hot-toast";
import {ReactComponent as CheckIcon} from '../../../assets/icons/toast-check-icon.svg';

function ToastNotifications() {
  return (
    <Toaster
      toastOptions={{
        className: "!bg-black !text-white",
        duration: 6000,
        style: {
          padding: 8,
          borderRadius:99
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({message}) => (
            <>
              <CheckIcon/>
              <div className="text-[12px] text-center">{message}</div>
              {/* {t.type !== "loading" && (
                <button onClick={() => toast.dismiss(t.id)}>
                  <span className="text-[22px]">&times;</span>
                </button>
              )} */}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}

export default ToastNotifications;
