import React, {useCallback, useEffect, useState} from 'react';
import OrdersCard from './OrdersCard';
import cart from '../../assets/img/no-orders.svg';
import {order, orders} from './ordersData';
import {useNavigate} from 'react-router-dom';
import {getItem} from '../../api/storage.service';
import {setAppLoading} from '../../redux/app/appSlice';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {useDispatch} from 'react-redux';
import {GetCustomerOrderHistoryByMsisdn} from '../../api/orders.service';
import {MockOrders} from '../../mock';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';

function Orders() {
	const [orderHistory, setOrderHistory] = useState();
	const [myOrders] = useState(orders);
	const navigate = useNavigate();
	const userMsisdn = getItem('u_msisdn');
	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const [visibleProducts, setVisibleProducts] = useState(3);

	const loadMore = useCallback(() => {
		setVisibleProducts(visibleProducts + 5);
	}, [visibleProducts]);

	const getOrderHistory = () => {
		dispatch(setAppLoading(true));
		GetCustomerOrderHistoryByMsisdn()
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					setOrderHistory(res?.data?.data);
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	useEffect(() => {
		getOrderHistory();
	}, [userMsisdn]);

	return (
		<>
			<PageTitle title='Business Hub - Orders' />

			<div className='lg:hidden'>
				{orderHistory?.length > 0 ? (
					<>
						<div className='py-10 px-[17px] '>
							<h3 className='text-xl font-bold'>Your Orders</h3>
							{orderHistory?.slice(0, visibleProducts).map((item, i) => (
								<OrdersCard key={i} order={item} />
							))}
							{visibleProducts < orderHistory.length && (
								<div onClick={loadMore} className='mt-5'>
									<button className='w-full border border-black rounded-[20px] bg-white p-2.5 font-bold cursor-pointer transition-colors duration-300 ease-in-out hover:bg-[#ffcc00] hover:border-[#ffcc00]' id='orders-load-btn'>
										Load more
									</button>
								</div>
							)}
						</div>
					</>
				) : (
					<div className='py-10 px-4 min-h-[500px]'>
						<h3 className='text-xl font-bold p-8 '>Your Order</h3>
						<div className=''>
							<img className='flex m-auto mt-[10%]' src={cart} />
							<div className='flex w-2/5 justify-center bg-[#ffcc00] mt-3 m-auto p-2.5 rounded-[20px] border-[none]'>
								<button onClick={() => navigate('/')}>Shop now</button>
							</div>
						</div>
					</div>
				)}
			</div>

			{/* Desktop ui */}
			<div className='hidden lg:block'>
				{orderHistory?.length > 0 ? (
					<>
						<div className='py-10 px-6'>
							<h3 className='text-xl font-bold'>Your Orders</h3>

							<div className='grid grid-cols-3 gap-3 '>
								{orderHistory?.slice(0, visibleProducts).map((item, i) => (
									<OrdersCard key={i} order={item} />
								))}
							</div>

							{visibleProducts < orderHistory.length && (
								<div onClick={loadMore} className='mt-5 flex justify-center '>
									<button className='w-1/2 border border-black rounded-[20px] bg-white p-2.5 font-bold cursor-pointer transition-colors duration-300 ease-in-out hover:bg-[#ffcc00] hover:border-[#ffcc00]' id='orders-load-btn'>
										Load more
									</button>
								</div>
							)}
						</div>
					</>
				) : (
					<div className='py-10 px-4 min-h-screen'>
						<h3 className='text-xl font-bold p-8 '>Your Order</h3>
						<div className=' flex flex-col justify-center items-center'>
							<img className='w-44 flex m-4 ' src={cart} />
							<p className='text-xl'>No Order Yet</p>
							<div className='flex w-60  justify-center bg-[#ffcc00] mt-3 m-auto p-2.5 rounded-[20px] border-[none]'>
								<button onClick={() => navigate('/')}>Shop now</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default Orders;
