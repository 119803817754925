import React, {useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {getItem} from '../../api/storage.service';
import {ResendOtp, VerifyOtp, VerifyOtpPayment} from '../../api/utilities.service';
import ReturnButton from '../../components/reusable/returnButton/ReturnButton';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {setAppLoading} from '../../redux/app/appSlice';
import {setLogin, setUserInformation} from '../../redux/user/userSlice';
import {cacheUserData} from '../../utils';
import './verification.scss';
import Countdown from 'react-countdown';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';
import LoginImage from '../../assets/img/homepageImage.jpg';
import OTPInput from 'otp-input-react';

const VerifyNumber = () => {
	let verificationInput = useRef(null);
	let navigate = useNavigate();
	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	let [showOTPTimer, setShowOTPTimer] = useState(true);
	const [otp, setOtp] = useState('');

	const isPaymentVerification = getItem('isPaymentVerification') === 'true';

	function verifyPinInputOnChange(evt) {
		switch (evt.key) {
			case 'Backspace':
				return;
			case 'Delete':
				return;
			default:
				if (!/^[0-9]+$/.test(evt.target.value)) {
					verificationInput.current.value = verificationInput.current.value.substring(0, verificationInput.current.value.length - 1);
				}
				return;
		}
	}

	const checkIfUserExist = (userExists) => {
		if (userExists) {
			navigate(getItem('AfterVerificationPath') ?? '/');
		} else {
			navigate('/signup');
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		dispatch(setAppLoading(true));
		const payload = {otp, msisdn: getItem('u_msisdn')};

		const verifyOtpFunction = isPaymentVerification ? VerifyOtpPayment : VerifyOtp;

		verifyOtpFunction(payload)
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res?.data?.success) {
					if (isPaymentVerification) {
						showSuccessMessage('Phone number verified successfully!', 'success');
						navigate('/choose-payment');
					} else {
						cacheUserData('username', res?.data?.data?.token, res?.data?.data?.expiry);
						dispatch(setLogin(true));
						dispatch(setUserInformation(res?.data?.data));
						checkIfUserExist(res?.data?.data.userExists);
					}
				} else {
					showErrorMessage(res?.data?.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	const renderer = ({minutes, seconds, completed}) => {
		if (completed) {
			return setShowOTPTimer(false);
		} else {
			return (
				<div className='text-sm text-center mt-8'>
					Resend OTP in <span className='min font-bold'>{minutes}</span>:<span className='sec font-bold'>{seconds}</span> secs
				</div>
			);
		}
	};

	const handleOTPResend = () => {
		setShowOTPTimer(true);
		ResendOtp({msisdn: getItem('u_msisdn')})
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					showSuccessMessage('OTP has been re-sent', 'success');
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	let verifyNumberContent = (
		<div className=''>
			<div className='mb-6'>
				<ReturnButton />
			</div>
			<div className='p-6 rounded-[20px]  bg-white shadow-[2px_15px_27px_2px_lightgrey] lg:flex lg:flex-col lg:justify-center'>
				<h1 className='font-bold text-[21px] mb-1'>{isPaymentVerification ? 'Verify your wallet number' : 'Verify your contact number'}</h1>
				<h2 className='font-bold text-md mb-1'>Enter one time pin</h2>
				<p className='text-sm text-gray-400 mb-5'>You will receive a one time pin via SMS. Please, enter the code below.</p>
				<form onSubmit={handleFormSubmit}>
					<div className='grid place-items-center'>
						<OTPInput inputClassName='border border-[#606D7A] !h-[50px] !w-[50px] rounded-[10px] outline-[#fc0]' className='' value={otp} onChange={setOtp} autoFocus OTPLength={4} otpType='number' disabled={false} secure />
					</div>
					{/* <input required={true} ref={verificationInput} onKeyUp={verifyPinInputOnChange} className='w-full text-sm rounded-xl bg-[#f2f2f2] my-2 outline-none p-3 pl-4' placeholder='Enter pin' type='text' /> */}
					<button type='submit' className='w-full p-[10px] mt-7 rounded-3xl bg-[#ffcc00] text-[15px] '>
						Confirm
					</button>
				</form>
				<div className='resend-link-container'>
					{showOTPTimer ? (
						<Countdown date={Date.now() + 59000} renderer={renderer} />
					) : (
						<p onClick={() => handleOTPResend()} className='mt-8 text-sm underline hover:font-bold cursor-pointer'>
							Resend the code
						</p>
					)}
				</div>
			</div>
		</div>
	);

	return (
		<>
			<PageTitle title='Business Hub - Verify OTP' />
			<div className='lg:flex min-h-screen'>
				<div className='py-10 px-5 lg:w-[50%] grid place-items-center'>
					<div className='lg:w-[60%] lg:my-0 my-12 w-full'>{verifyNumberContent}</div>
				</div>
				<div className='w-[50%] hidden lg:block bg-contain bg-center bg-[#fec527] bg-no-repeat' style={{backgroundImage: `url(${LoginImage})`}} >
					{/* <img src={LoginImage} alt='Login image' className='object-cover' /> */}
				</div>
			</div>
		</>
	);
};

export default VerifyNumber;
