import React from 'react';
import './recommendedProducts.scss';
import AirpodsImg from '../../../assets/img/airpods.png';
import SaveItemImg from '../../../assets/img/saveItem.png';
import {truncateText} from '../../../utils';

function RecommendedProducts({img, title, price, discount, rating, description}) {
	const noPrice = true;
	return (
		<>
			<div className='text-left mr-5 mt-0.5 p-[9px] rounded-[20px] bg-white shadow-lg'>
				<div className=' '>
					<div style={{backgroundImage: `url(${img})`}} className='bg-no-repeat bg-cover rounded-xl h-[160px] w-[200px]'></div>
				</div>

				<p className='text-[15px] font-bold text-left mt-2 shorten-text w-[190px]' title={title}>{title}</p>
				{noPrice ? <p className='mt-0 text-gray-500 text-[11px] shorten-text w-[190px]' title={description}>{description}</p> : <p className='text-[23px] text-start ml-1 mt-0.5 '> {'GHc ' + price}</p>}
				<div className='justify-between  customFlex'>
					<div className=''>
						{noPrice ? (
							''
						) : (
							<p className='text-[13px] text-start font-thin ml-1.5 mt-0.5'>
								<strike>{discount}</strike>
							</p>
						)}

						<div className='customFlex '>
							{Array(rating)
								.fill()
								.map((_, i) => (
									<small key={i} className='mr-[-3px] stars '>
										⭐
									</small>
								))}
							<p className=' text-[13px] text-start font-thin ml-1.5 mt-0.5  text-[#9f9f9f] '></p>
						</div>
					</div>

					<img alt='save item' src={SaveItemImg} className='object-contain' />
				</div>
			</div>
		</>
	);
}

export default RecommendedProducts;
