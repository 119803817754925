import React, {useEffect} from 'react';
import {GroupIcon} from '../../../icons/GroupIcon';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ThreedotsIcon} from '../../../icons/ThreedotsIcon';
import {IndividualIcon} from '../../../icons/IndividualIcon';
import CustomDropdownMenu from '../../../customDropdown/_component';
import {getItem} from '../../../../../api/storage.service';
import {formToJSON} from 'axios';
import {useAlertMessage} from '../../../../../hooks/useAlertMessage';
import {AddGroup, AddGroupMember, UpdateGroup, UpdateGroupMember} from '../../../../../api/workflow.service';
import {setAppLoading} from '../../../../../redux/app/appSlice';
import {useDispatch, useSelector} from 'react-redux';
import {setSelectedGroup, setSelectedMember} from '../../../../../redux/product/productSlice';
import {uuid} from '../../../../../utils';

const GroupMembers = (props) => {
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const selectedGroup = useSelector((state) => state.product.selectedGroup);
	const selectedMember = useSelector((state) => state.product.selectedMember);
	const [showMembers, setShowMembers] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleUpdateGroupMember = () => {
		UpdateGroupMember(selectedMember)
			.then((res) => {
				setAppLoading(false);
				if (res.data.success) {
					showSuccessMessage(res.data.message, 'success');
					props?.refetch();
					dispatch(
						setSelectedGroup({
							...selectedGroup,
							['groupMembers']: [...selectedGroup.groupMembers].map((x) => {
								if (x?.id === selectedMember?.id) {
									x = selectedMember;
								}
								return x;
							}),
						}),
					);
				} else {
					showErrorMessage(res.data.message, 'error');
				}
				setShowMembers(false);
			})
			.catch((err) => {
				setShowMembers(false);
				setAppLoading(false);
				showErrorMessage('An Error occured !', 'error');
				console.log('Error Log:', err);
			});
	};

	const handleAddMemberToGroup = () => {
		const addMemberPayload = {
			groupId: selectedGroup?.id,
			msisdn: selectedMember?.msisdn,
			description: selectedMember?.description,
		};
		AddGroupMember(addMemberPayload)
			.then((res) => {
				setAppLoading(false);
				if (res.data.success) {
					showSuccessMessage('Group Member Updated Successfully', 'success');
					props?.refetch();
					dispatch(setSelectedGroup({...selectedGroup, ['groupMembers']: [...(selectedGroup?.groupMembers ?? []), addMemberPayload]}));
				} else {
					showErrorMessage(res.data.message, 'error');
				}
				setShowMembers(false);
			})
			.catch((err) => {
				setShowMembers(false);
				setAppLoading(false);
				showErrorMessage('An Error occured !', 'error');
				console.log('Error Log:', err);
			});
	};

	const handleMemberSubmit = () => {
		if (!selectedMember?.description) return showErrorMessage('Member name is required', 'error');
		if (!selectedMember?.msisdn) return showErrorMessage('Phone number is required', 'error');

		//update an existing group member for a new group
		if (selectedMember?.temporaryId?.length > 0) {
			setShowMembers(false);
			return dispatch(
				setSelectedGroup({
					...selectedGroup,
					['groupMembers']: [...selectedGroup.groupMembers].map((x) => {
						if (x?.temporaryId === selectedMember?.temporaryId) {
							x = selectedMember;
						}
						return x;
					}),
				}),
			);
		}

		if (selectedMember?.id?.length > 0) {
			//update an existing group member for an exisiting group
			handleUpdateGroupMember();
		} else {
			if (selectedGroup?.id?.length > 0) {
				//add a group member to an exisiting group
				handleAddMemberToGroup();
			} else {
				//add a new member to a new group
				dispatch(setSelectedGroup({...selectedGroup, ['groupMembers']: [...(selectedGroup?.groupMembers ?? []), {...selectedMember, ['temporaryId']: uuid()}]}));
			}
		}
		setShowMembers(false);
	};

	const memberUpdateHandler = (e) => {
		const {name, value} = e.target;
		dispatch(setSelectedMember({...selectedMember, [name]: value}));
	};

	const handleEditGroup = (payload) => {
		const editPayload = {
			groupId: selectedGroup?.id,
			groupName: selectedGroup?.groupName,
		};

		UpdateGroup(editPayload)
			.then((res) => {
				setAppLoading(false);
				if (res.data.success) {
					showSuccessMessage('Group Updated Successfully', 'success');
					props?.refetch();
				} else {
					showErrorMessage(res.data.message, 'error');
				}
				props?.setShowModal(false);
			})
			.catch((err) => {
				props?.setShowModal(false);
				setAppLoading(false);
				showErrorMessage('An Error occured !', 'error');
				console.log('Error Log:', err);
			});
	};

	const handleAddGroup = (payload) => {
		AddGroup(payload)
			.then((res) => {
				setAppLoading(false);
				if (res.data.success) {
					showSuccessMessage('Group Added Successfully', 'success');
					props?.refetch();
				} else {
					showErrorMessage(res.data.message, 'error');
				}
				props?.setShowModal(false);
			})
			.catch((err) => {
				props?.setShowModal(false);
				setAppLoading(false);
				showErrorMessage('An Error occured !', 'error');
				console.log('Error Log:', err);
			});
	};

	const handleGroupSubmit = () => {
		if (!selectedGroup?.groupName) return showErrorMessage('Group name is required', 'error');
		if (!selectedGroup?.groupMembers?.length > 0) return showErrorMessage('Add at least one member', 'error');

		const payload = {
			msisdn: getItem('u_msisdn'),
			groupName: selectedGroup?.groupName,
			groupMembers: [...selectedGroup?.groupMembers],
		};

		if (selectedGroup?.id?.length > 0) {
			handleEditGroup(payload);
		} else {
			handleAddGroup(payload);
		}
	};

	const clearMemberForm = () => {
		var inputs = document.querySelectorAll('#member_form input');
		for (var i = 0; i < inputs?.length; i++) {
			inputs[i].value = '';
		}
		dispatch(
			setSelectedMember({
				description: '',
				msisdn: '',
			}),
		);
	};

	const clearGroupForm = () => {
		var inputs = document.querySelectorAll('#group_form input');
		for (var i = 0; i < inputs?.length; i++) {
			inputs[i].value = '';
		}
		dispatch(setSelectedGroup({}));
	};

	if (props?.onInit) {
		props?.onInit({
			clearGroupForm,
		});
	}

	if (showMembers) {
		return (
			<div className='block p-6 rounded-2xl bg-white mx-2 md:max-w-xl'>
				<button onClick={() => setShowMembers(false)} className='returnButton !text-[11px]'>
					BACK
				</button>
				<h1 className='font-bold text-lg pt-3'>Group Member</h1>
				<form id='member_form'>
					<p className='text-sm mb-3'>Please enter member name and number .Only MTN numbers should be added for group members</p>
					<p className='text-xs text-gray-500 pb-1'>ENTER NAME AND NUMBER</p>
					<div>
						<input type='text' className='mt-1 !p-3 text-sm w-full focus:outline-none outline-none border-0 bg-gray-100 rounded-md' placeholder='Enter Member Name' name='description' value={selectedMember?.description} onChange={memberUpdateHandler} required />
					</div>
					<div>
						<input type='text' className='mt-1 !p-3 text-sm w-full focus:outline-none outline-none border-0 bg-gray-100 rounded-md' placeholder='Enter Member Phone number' name='msisdn' value={selectedMember?.msisdn} onChange={memberUpdateHandler} required />
					</div>
					<div className='grid place-items-center'>
						<button type='button' className='rounded-full !w-full bg-[#ffcc00] hover:bg-[#ffcc0079] p-3 text-xs mt-6' onClick={handleMemberSubmit}>
							{selectedMember?.id?.length > 0 || selectedMember?.temporaryId?.length > 0 ? 'Update Member' : 'Add Member'}
						</button>
					</div>
				</form>
			</div>
		);
	}
	return (
		<div className='block p-6 rounded-2xl bg-white mx-2 md:max-w-xl'>
			<h1 className='font-bold text-lg pt-3'>Group Details</h1>
			<p className='text-sm'>Please enter group detail. Only MTN numbers should be added as group members</p>
			<form id='group_form'>
				<div>
					<input type='text' name='groupName' className='mt-1 !p-3 text-sm w-full focus:outline-none outline-none border-0 bg-gray-100 rounded-md' placeholder='Enter Group Name' value={selectedGroup?.groupName} required onChange={(e) => dispatch(setSelectedGroup({...selectedGroup, [e.target.name]: e.target.value}))} />
				</div>
				<p className='!font-extrabold text-xs mt-5'>
					Group members {selectedGroup?.groupMembers?.length > 0 && `(${selectedGroup?.groupMembers?.length})`} <span className='text-[12px]'></span>
				</p>

				<div className='flex items-center gap-1 cursor-pointer'>
					<GroupIcon className='w-5 h-5' />
					<p
						className='underline text-[10px] cursor-pointer hover:font-bold'
						onClick={() => {
							setShowMembers(true);
							clearMemberForm();
						}}
					>
						Add Members
					</p>
				</div>
				<div className='mt-4'>
					{selectedGroup?.groupMembers?.length > 0 ? (
						selectedGroup?.groupMembers?.map((member, idx) => (
							<>
								<div className='flex items-center justify-between gap-2 mb-2 cursor-pointer' key={idx}>
									<div className='flex items-center gap-2'>
										<div className='bg-[#fc0] rounded-full p-1'>
											<IndividualIcon className='w-5 h-5' />
										</div>
										<p className='text-xs'>
											{member?.description}
											<p className='text-xs text-gray-500'>{member?.msisdn}</p>
										</p>
									</div>
									<CustomDropdownMenu type='member' data={member} refetch={() => props?.refetch()} setShowMembers={setShowMembers} />
								</div>
							</>
						))
					) : (
						<>
							<p className='text-xs text-gray-500'>Sorry, you have no members yet</p>
						</>
					)}
				</div>
				<div className='grid place-items-center'>
					<button type='button' onClick={handleGroupSubmit} className='rounded-full !w-full bg-[#ffcc00] hover:bg-[#ffcc0079] p-3 text-xs mt-12'>
						{selectedGroup?.id?.length > 0 ? 'Update Group' : 'Save Group'}
					</button>
				</div>
			</form>
		</div>
	);
};

export default GroupMembers;
