import React from 'react';
import ReactMarkdown from 'react-markdown';

function TextLabelEntry({data, index}) {
	return (
		<>
			<div className='flexColumn my-3'>
				<div className={'error-placeholder error-comp' + data?.componentId}></div>

				<ReactMarkdown
					components={{
						a: ({node, ...props}) => <a style={{color: 'blue'}} {...props} />,
					}}
				>
					{data?.options.find((x) => x.key === 'CONTENT')?.value}
				</ReactMarkdown>

				<input type='hidden' name={`response[${index}][value]`} value={data?.options.find((x) => x.key === 'CONTENT')?.value} className='text-[13px] w-full mt-[22px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' />
				<input type='hidden' name={`response[${index}][controlName]`} value={data?.displayName} />
				<input type='hidden' name={`response[${index}][meta]`} className='text-[13px] w-full my-[10px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' />
				<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} className='text-[13px] w-full mt-[22px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' />
				<input type='hidden' name={`response[${index}][id]`} value={data?.id} className='text-[13px] w-full mt-[22px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' />
			</div>
		</>
	);
}

export default TextLabelEntry;
