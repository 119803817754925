import {getAxios, getConfig} from '../utils';

const apiAxios = () => getAxios(getConfig().apiBaseUrl.utilitiesBaseUrl);

export const UserLogin = async (payload) => await apiAxios().post('/Auth/login', {...payload});
export const VerifyOtp = async (payload) => await apiAxios().post('/Auth/verify-otp', {...payload});
export const ResendOtp = async (payload) => await apiAxios().post('/Auth/Resend-OTP', {...payload});

export const AddShopUser = async (payload) => await apiAxios().post('/Auth/AddShopUser', {...payload});
export const CheckShopUser = async (payload) => await apiAxios().post('/Auth/ShopUserExistsByMsisdnCheck', {...payload});

export const GetHomepageSliders = async () => await apiAxios().post('/Auth/GetMainImageSliders');
export const GhanaCardValidation = async (payload) => await apiAxios().post('/Auth/GhanaCardValidation', {...payload});

export const GetEmployeeCount = async () => await apiAxios().post('/Shop/GetAllBusinessEmployeeCount');
export const GetAllBusinessIndustry = async () => await apiAxios().post('/Shop/GetAllBusinessIndustry');
export const GetAllBusinessSegment = async () => await apiAxios().post('/Shop/GetAllBusinessSegment');
export const AddTellMe = async (payload) => await apiAxios().post('/Shop/AddTellMe', {...payload});

export const LoginPayment = async (payload) => await apiAxios().post('/Auth/loginPayment', {...payload});
export const VerifyOtpPayment = async (payload) => await apiAxios().post('/Auth/verify-otp_payment', {...payload});

export const CheckFibreAvailability = async (payload) => await apiAxios().post('/Verification/CheckAvailability', {...payload});

export const GetSIMDetails = async (payload) => await apiAxios().post('/Verification/GetSIMDetails', {...payload});
