import {useRef} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {LoginModal} from './modal';
import './Login.scss';
import {ReactComponent as PhoneNumberInputIcon} from '../../assets/icons/phone-number-input-icon.svg';
import {ReactComponent as FacebookLoginIcon} from '../../assets/icons/facebook-login-icon.svg';
import {ReactComponent as TwitterLoginIcon} from '../../assets/icons/twitter-login-icon.svg';
import {ReactComponent as GoogleLoginIcon} from '../../assets/icons/google-login-icon.svg';
import LoginImage from '../../assets/img/homepageImage.jpg';
import {UserLogin} from '../../api/utilities.service';
import {setAppLoading} from '../../redux/app/appSlice';
import {useDispatch} from 'react-redux';
import {storeItem} from '../../api/storage.service';
import {setLogin} from '../../redux/user/userSlice';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {validator} from '../../utils';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';

const Login = () => {
	let phoneInput = useRef(null);
	let navigate = useNavigate();
	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();

	const handleFormSubmit = (e) => {
		e.preventDefault();
		dispatch(setAppLoading(true));

		var emptyMsisdn = validator([{rule: 'empty', message: 'msisdn is required'}], phoneInput?.current.value);
		var validateMsisdn = validator([{rule: 'msisdn', message: 'msisdn is required'}], phoneInput?.current.value);

		if (emptyMsisdn) {
			dispatch(setAppLoading(false));
			showErrorMessage('Phone number is required', 'error');
			return;
		}

		if (validateMsisdn) {
			dispatch(setAppLoading(false));
			showErrorMessage('Phone number is not valid', 'error');
			return;
		}

		UserLogin({msisdn: phoneInput?.current?.value})
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					storeItem('u_msisdn', phoneInput?.current?.value);
					setTimeout(() => {
						navigate('/verify-number');
					}, 100);
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	let loginContent = (
		<div className='p-6 rounded-[20px]  bg-white shadow-[2px_15px_27px_2px_lightgrey] lg:flex lg:flex-col lg:justify-center'>
			<h1 className='text-2xl font-bold mb-5 text-center'>Login to Continue</h1>
			<form onSubmit={handleFormSubmit}>
				<div className=' flex mb-1.5 justify-start items-center bg-[#f2f2f2] p-4 rounded-lg gap-2'>
					<PhoneNumberInputIcon className='mr-1' />
					<input ref={phoneInput} type={'tel'} className='outline-none border-none bg-transparent text-sm placeholder:font-bold w-full' placeholder='Phone number' required />
				</div>
				<div className='centerItems'>
					<button type='submit' className='cursor-pointer w-full max-w-500 mt-5 border-none text-base font-bold rounded-full py-3 px-4 bg-[#ffca05] flex justify-center items-center transition-colors duration-300 ease-in-out hover:bg-[#ffc90571]'>
						Login
					</button>
				</div>
			</form>
			{/* <div className='mt-2.5 flex justifySpaceBetween '>
					<div className='row align-center'>
					<input className='remember-me-checkbox' type={'checkbox'} />
					<span className='remember-me-text'>Remember me</span>
				</div>
					<div className='row align-center'>
					<a className='forgot-password-link'>Forgot password</a>
				</div>
				</div> */}
			{/* <div className=' flex justify-center mt-3.5 text-xs font-bold text-black'>or login with social account</div>
				<div className='flex justify-center items-center w-full mt-4'>
					<div className='ml-2 mb-2.5 w-20 h-10 rounded-full bg-[#ffca05] flex justify-center items-center cursor-pointer'>
						<FacebookLoginIcon className='w-5 h-5' />
					</div>
					<div className='ml-2 mb-2.5 w-20 h-10 rounded-full bg-[#ffca05] flex justify-center items-center cursor-pointer'>
						<TwitterLoginIcon className='w-5 h-5' />
					</div>
					<div className='ml-2 mb-2.5 w-20 h-10 rounded-full bg-[#ffca05] flex justify-center items-center cursor-pointer'>
						<GoogleLoginIcon className='w-5 h-5' />
					</div>
				</div> */}
			{/* <div className='h-[0.2px] bg-[lightgray] my-3'></div>
				<div className=' row justify-center mt-6'>
					<span className='text-grey'>Don't have an acccount? </span>
					<Link to={'/signup'}>
						<div className='sign-up-text'>Sign up</div>
					</Link>
				</div> */}
		</div>
	);

	return (
		<>
			<PageTitle title='Business Hub - Login' />
			<div className='lg:flex min-h-screen'>
				<div className=' py-10 px-5 lg:w-[50%] grid place-items-center '>
					<div className='lg:w-[60%] lg:mt-0 mt-20 w-full'>{loginContent}</div>
				</div>
				<div className='w-[50%] hidden lg:block bg-contain bg-center bg-[#fec527] bg-no-repeat' style={{backgroundImage: `url(${LoginImage})`}} >
					{/* <img src={LoginImage} alt='Login image' className='object-cover' /> */}
				</div>
			</div>
		</>
	);
};

export default Login;
