import {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {GetSIMDetails} from '../../../../api/utilities.service';
import {Asterisk} from '../../asterisk/Asterisk';
import {getItem} from '../../../../api/storage.service';

function IDValidationFieldEntry({data, index}) {
	const [selectedID, setSelectedID] = useState('');
	const [idNumber, setIdNumber] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [isAutoFilled, setIsAutoFilled] = useState(false);
	const dispatch = useDispatch();
	const separator = ',';
	const isFieldRequired = data?.required;
	const allowedIds = () => data?.options?.filter((x) => x.key === 'ALLOWED-ID')[0]?.value.split(separator);

	const clearFields = () => {
		setIdNumber('');
		setFirstName('');
		setLastName('');
		document.getElementById('fullname').value = '';
		setSelectedID('');
		setIsAutoFilled(false);
	};

	const handleIDChange = (event) => {
		const cleanID = event.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
		setSelectedID(cleanID);
	};

	useEffect(() => {
		const number = getItem('u_msisdn');
		if (number) {
			GetSIMDetails({msisdn: number})
				.then((response) => {
					if (response.data.success) {
						const simData = response.data.data;
						setIdNumber(simData.documentIdNumber || '');
						setFirstName(simData.firstName || '');
						setLastName(simData.lastName || '');
						const fullNameField = document.getElementById('fullname');
						if (fullNameField) {
							fullNameField.value = `${simData.firstName || ''} ${simData.lastName || ''}`.trim();
							fullNameField.readOnly = true; // Ensure read-only after auto-population
						}
						setSelectedID(allowedIds()[0]);
						setIsAutoFilled(true);
					}
				})
				.catch((error) => {
					console.error('Error fetching SIM details:', error);
				});
		}
	}, []);

	return (
		<div className='flexColumn requestEntry_field_container'>
			{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' && (
				<small className='requestEntry_label'>
					{data?.displayName} {isFieldRequired && <Asterisk />}
				</small>
			)}
			<div className='id-entry'>
				<div className='grid grid-cols-2 gap-3'>
					<select name={`response[${index}][meta]`} className='requestEntry_formFields text-[13px] w-full my-[10px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' value={selectedID} onChange={handleIDChange} disabled={isAutoFilled}>
						<option value=''>-- Select ID Type --</option>
						{allowedIds().map((card, i) => (
							<option value={card} key={i}>
								{card.replace(/([A-Z])/g, ' $1').trim()}
							</option>
						))}
					</select>
					<input onChange={(e) => setIdNumber(e.target.value)} value={idNumber} placeholder='ID Number' className='requestEntry_formFields text-[13px] w-full my-[10px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' type='text' readOnly={isAutoFilled} />
				</div>
				{/* <input id='fullname' className='requestEntry_formFields text-[13px] w-full my-[10px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' type='text' /> */}
				<input type='hidden' name={`response[${index}][value]`} value={`${idNumber}${separator}${firstName}${separator}${lastName}`} />
				<input type='hidden' name={`response[${index}][meta]`} value={selectedID} />
				<input type='hidden' name={`response[${index}][controlName]`} value={data?.displayName} />
				<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
				<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
			</div>
		</div>
	);
}

export default IDValidationFieldEntry;
