import React, {useState} from 'react';
import {getItem} from '../../../api/storage.service';
import {useSelector} from 'react-redux';

const DirectionSvgIcon = () => {
	return (
		<svg className='cursor-pointer' width='50' height='50' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.6002 19.3751V15.5001H12.4002V20.1501H9.30018V13.9501C9.30018 13.0201 9.92018 12.4001 10.8502 12.4001H18.6002V8.52506L24.0252 13.9501L18.6002 19.3751ZM30.535 14.415L16.585 0.465C15.9651 -0.155 15.035 -0.155 14.415 0.465L0.465 14.415C-0.155 15.035 -0.155 15.9651 0.465 16.585L14.415 30.535C15.035 31.155 15.9651 31.155 16.585 30.535L30.535 16.585C31.155 15.9651 31.155 15.0351 30.535 14.415Z'
				fill='#333333'
			/>
		</svg>
	);
};

export const BranchCards = ({data, branchData, setSelectedBranch, setShowModal}) => {
	const storedLocationDetails = useSelector((state) => state?.location?.location);
	const fromLat = storedLocationDetails?.lat;
	const fromLon = storedLocationDetails?.lng;
	const toLat = parseFloat(branchData?.coordinates?.split(',')[0]);
	const toLon = parseFloat(branchData?.coordinates?.split(',')[1]);
	const workflowComponents = JSON.parse(getItem('workflowComponents'))?.sort((a,b)=> a?.orderIndex - b?.orderIndex);

	const handleOpenInGoogleMaps = () => {
		if (fromLat && fromLon && toLat && toLon) {
			if (window.confirm('This action would open Google maps in another screen. Do you want to proceed ?')) {
				const googleMapsUrl = `https://www.google.com/maps/dir/${fromLat},${fromLon}/${toLat},${toLon}`;
				// const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${locationDetails?.latitude},${locationDetails?.longitude}`;

				window.open(googleMapsUrl, '_blank');
			}
		} else {
			return alert('Coordinates not found !', 'error');
		}
	};
 
	const isLastItem = (obj, array) => {
		const index = array.indexOf(workflowComponents?.find((x)=> x?.id === obj?.componentId));
		return index === array.length - 1;
	};


	return (
		<div>
			<div className='border-[1px] border-gray-300 rounded-[29px] p-[19px] cursor-pointer'>
				<div className=' bg-gray-100 py-[21px] px-[20px] flex rounded-[20px] justify-between items-center'>
					<div>
						<p className='!font-bold !text-[18px]'>{branchData?.pickupLocation}</p>
						<p className='!text-[15px]'>{`${branchData?.town}, ${branchData?.region}`}</p>
						<p className='text-gray-400 !text-[13px]'>{branchData?.distanceInKM} </p>
					</div>
					<div onClick={handleOpenInGoogleMaps}>
						<DirectionSvgIcon />
					</div>
				</div>
				<div className='pt-[16px] flex gap-3'>
					<button type='submit' className={`${isLastItem(data, workflowComponents) ? 'form-wizard-submit' : 'form-wizard-next'} rounded-full w-full border-[1px] border-[#ffcc00] bg-[#ffcc00] hover:bg-[#ffcc008c] py-[9px] text-[14px] font-bold flex-[0.7]`} onClick={() => setSelectedBranch(branchData)}>
						Select store
					</button>
					<button
						onClick={() => {
							setShowModal(true);
							setSelectedBranch(branchData);
						}}
						type='button'
						className='rounded-full w-full border-[1px] border-black bg-transparent hover:bg-[#000] py-[9px] hover:text-white text-[14px] font-bold flex-[0.3]'
					>
						Info
					</button>
				</div>
			</div>
		</div>
	);
};
