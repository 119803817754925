function CheckboxView({data, index, value}) {
	const separator = ';;';
	const items = () => data?.options?.filter((x) => x.key == 'ITEMS')[0].value.split(separator);
	const values = () => value?.value.split(separator);

	const checkStyles = {
		fontSize: '12px',
		color: 'red !important',
		width: '289px',
		textAlign: 'left',
	};

	const checkField_containerStyles = {
		marginRight: '183px',
	};

	return (
		<>
			<div className='flexColumn requestEntry_field_container' style={checkStyles}>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? <small className='requestEntry_label'>{data?.displayName}</small> : ''}
				<div className='radioField_container' style={checkField_containerStyles}>
					{items().map((item, item_key) => (
						<div key={item_key} style={{marginTop: '7px'}}>
							<input id={'cb_' + data?.componentId + '_' + item_key} className={'cb_' + data?.componentId} type='checkbox' disabled={true} checked={values()?.includes(item)} />
							<label style={{marginLeft: '10px'}} htmlFor={'cb_' + data?.componentId + '_' + item_key}>
								{item}
							</label>
						</div>
					))}
				</div>

				<input type='hidden' name={`response[${index}][meta]`} />
				<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
			</div>
		</>
	);
}

export default CheckboxView;
