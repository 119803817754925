import React, {useState, useEffect} from 'react';
import toast from 'react-hot-toast';

export const useAlertMessage = () => {
	const [message, setMessage] = useState(null);

	const notifySuccess = (message) => {
		toast.success(message);
	};
	const notifyError = (message) => {
		toast.error(message);
	};

	useEffect(() => {
		if (message) {
			const {type, text} = message;
			switch (type) {
				case 'success':
					notifySuccess(text);
					break;
				case 'error':
					notifyError(text);
					break;
				default:
			}
			setMessage(null);
		}
	}, [message]);

	function showSuccessMessage(text, type) {
		setMessage({type, text});
	}

	function showErrorMessage(text, type) {
		setMessage({type, text});
	}

	return {showSuccessMessage, showErrorMessage};
};
