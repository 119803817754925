function MsisdnFieldView({data, index, value}) {
	return (
		<>
			<div className='flexColumn requestEntry_field_container'>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? <small className='requestEntry_label'>{data?.displayName}</small> : ''}
				<input type='text' className='text-[13px] w-full my-[10px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' readOnly={true} value={value?.value} />
			</div>
		</>
	);
}

export default MsisdnFieldView;
