import {useQuery} from 'react-query';
import {GetNearestBranches} from '../../../../api/workflow.service';
import {BranchCards} from '../../branchCard';
import {useEffect, useState} from 'react';
import {Modal} from '../../modal/_component';
import {getItem} from '../../../../api/storage.service';
import {useSelector} from 'react-redux';
// import {Loader} from 'google-maps';
import YellowPinIcon from '../../../../assets/icons/yellowpin.png';
import mapStyle from '../../pickupLocation/mapStyle';
import {useJsApiLoader, GoogleMap, MarkerF} from '@react-google-maps/api';

const options = {
	libraries: ['places'],
};

function NearestBranchFieldEntry({data, index}) {
	const [google, setGoogle] = useState();
	const mapKey = useSelector((state) => state?.app?.config?.mapKey);
	const coordinates = useSelector((state) => state?.location?.location);
	// const loader = new Loader(mapKey, options);
	const {isLoaded} = useJsApiLoader({
		googleMapsApiKey: mapKey,
		...options,
	});
	const separator = ';;';
	const items = data?.options?.find((x) => x.key == 'ITEMS')?.value.split(separator);
	const fetchDeviceTypeId = getItem('deviceTypeId');
	const nearestBranchesQuery = useQuery({
		retry: (count, err) => count < 3,
		queryKey: ['nearestBranchKey'],
		queryFn: () => GetNearestBranches(fetchDeviceTypeId ? fetchDeviceTypeId : 'all', coordinates?.lat, coordinates?.lng).then((res) => res?.data),
		enabled: coordinates?.lat && coordinates?.lng ? true : false,
		// onSuccess: (data) => onProductFetchSuccess(data),
	});
	const [selectedBranch, setSelectedBranch] = useState('');
	const [showModal, setShowModal] = useState(false);
	const storedLocationDetails = useSelector((state) => state?.location?.location);
	const [locationInfo, setLocationInfo] = useState(false);
	const fieldErrorMessages = useSelector((state) => state.product.fieldErrorMessages);
	const hasErrorMessage = fieldErrorMessages.some((item) => item.id === data?.id);

	const defaultCenter = {
		lat: parseFloat(selectedBranch?.coordinates?.split(',')[0]),
		lng: parseFloat(selectedBranch?.coordinates?.split(',')[1]),
	};

	const containerStyle = {
		width: '100%',
		height: '320px',
		display: defaultCenter ? 'block' : 'none',
	};

	// loader.load().then((response) => setGoogle(response));

	// useEffect(() => {
	// 	if (google) {
	// 		var map = new google.maps.Map(document.getElementById('branch_map'), {
	// 			center: defaultCenter,
	// 			zoom: 15,
	// 			disableDefaultUI: true,
	// 			gestureHandling: 'greedy',
	// 			styles: mapStyle,
	// 		});
	// 		new google.maps.Marker({
	// 			position: defaultCenter,
	// 			map,
	// 			title: 'Hello World!',
	// 			icon: YellowPinIcon,
	// 		});
	// 	}
	// 	if (locationDetails?.latitude) {
	// 		setLocationInfo(true);
	// 	}
	// }, [google, locationDetails?.latitude]);
	return (
		<div>
			<div className='pb-10'>
				{nearestBranchesQuery?.isLoading ? (
					<div className='grid place-items-center h-[400px]'>Loading...</div>
				) : (
					<div className={`space-y-5`} id='branch_list'>
						<p className='text-gray-600 text-[15px] !font-bold pl-3 pt-7'>OFFICES NEAREST TO YOU ({nearestBranchesQuery?.data?.length} RESULTS)</p>
						{nearestBranchesQuery?.data?.length > 0 &&
							nearestBranchesQuery?.data
								?.sort((a, b) => {
									const distanceA = parseFloat(a.distanceInKM);
									const distanceB = parseFloat(b.distanceInKM);
									return distanceA - distanceB;
								})
								?.map((branch) => (
									<div key={branch?.id}>
										<BranchCards data={data} branchData={branch} selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} setShowModal={setShowModal} />
									</div>
								))}
					</div>
				)}
			</div>
			<p className='text-red-600 text-[12px] mb-5'>{hasErrorMessage && `${data?.displayName} is required`}</p>

			<input type='hidden' name={`response[${index}][value]`} value={selectedBranch?.pickupLocation} />
			<input type='hidden' name={`response[${index}][meta]`} />
			<input type='hidden' name={`response[${index}][controlName]`} value={data?.displayName} />
			<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
			<input type='hidden' name={`response[${index}][id]`} value={data?.id} />

			<Modal open={showModal} close={setShowModal} closeOnOverlay={true}>
				<div className='md:px-2'>
					<div className='block py-4 px-4 rounded bg-white md:w-full'>
						{/* <button type='button' onClick={() => setShowModal(false)} className='bg-[#ffca05] font-bold text-sm px-1.5 py-1 rounded-sm border-[none] hover:bg-[#ffc9059c]'>
							CLOSE
						</button> */}
						<div className='md:grid grid-cols-2 gap-4'>
							<div>
								<h1 className='text-[#1c1c1c] font-bold text-[25px] mt-[26px] mb-1'>{selectedBranch?.pickupLocation}</h1>
								<p className='font-medium mb-4'>Store Details</p>
								{!isLoaded ? (
									<p>Please Wait...</p>
								) : (
									selectedBranch && (
										<GoogleMap
											center={defaultCenter}
											zoom={15}
											mapContainerStyle={containerStyle}
											options={{
												styles: mapStyle,
												gestureHandling: 'greedy',
												zoomControl: false,
												streetViewControl: false,
												mapTypeControl: false,
												fullscreenControl: false,
												keyboardShortcuts: false,
											}}
										>
											<MarkerF icon={YellowPinIcon} position={defaultCenter} />
										</GoogleMap>
									)
								)}

								<p className='text-[11px] text-center mt-2 mb-5 mx-3'>We’ll send you an SMS or email once your order is ready for collection. You’ll then have 7 days to collect your order.</p>
							</div>
							<div>
								<p className='text-md text-center mb-4 md:mt-[35px]'>Business Hours (Next 7 Days)</p>
								<div className='overflow-x-auto'>
									<table className='min-w-full divide-y divide-gray-200'>
										<tbody className='bg-white divide-y divide-gray-200'>
											{selectedBranch?.openTimes?.map((item, index) => {
												return (
													<tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
														<td className='px-6 py-4 text-xs md:text-md'>{item?.day}</td>
														<td className='px-6 py-4 text-xs md:text-md'>{item?.open}</td>
														<td className='px-6 py-4 text-xs md:text-md'>
															{item?.openingTime} - {item?.closingTime}
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default NearestBranchFieldEntry;
