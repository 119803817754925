import React, {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {AddItemToCart, AddItemToCartNew, BuyNow, SavePreorder} from '../../api/cart.service';
import {getItem, storeItem} from '../../api/storage.service';
import {GetWorkflowById} from '../../api/workflow.service';
import editorControls from '../../components/reusable/workflowComponents/editorComponents';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {setAppLoading} from '../../redux/app/appSlice';
import './selectbeneficiary.scss';
import {FormWizard, WizardStage} from '../../components/reusable/form_wizard/_component';
import {formToJSON} from 'axios';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';
import {validator} from '../../utils';
import {setFieldErrorMessages, setPaymentIsSuccessful} from '../../redux/product/productSlice';
import toast from 'react-hot-toast';

const SelectBeneficiary = () => {
	let formWizard;
	const navigate = useNavigate();
	const productWorkflow = useSelector((state) => state.product.productWorkflow);
	const selectedProduct = useSelector((state) => state.product.selectedProduct);
	const purchaseForGroup = useSelector((state) => state.product.purchaseForGroup);
	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const queryClient = useQueryClient();
	const [currentTab, setCurrentTab] = useState(0);
	const disableWorkflowCta = useSelector((state) => state.product.disableWorkflowCta);
	const fieldErrorMessages = useSelector((state) => state.product.fieldErrorMessages);

	const workflowQuery = useQuery({
		retry: (count, err) => count < 3,
		queryKey: ['getProductWorkflow', selectedProduct?.workflowId],
		queryFn: () => GetWorkflowById(selectedProduct?.workflowId).then((res) => res.data),
		onSuccess: (res) => {
			storeItem('workflowComponents', JSON.stringify(res?.data?.components));
		},
	});

	// const getProductWorkflow = (workflowId) => {
	// 	dispatch(setAppLoading(true));
	// 	if (workflowId) {
	// 		GetWorkflowById(workflowId)
	// 			.then((res) => {
	// 				dispatch(setAppLoading(false));
	// 				if (res.data.success) {
	// 					var data = res.data.data;
	// 					dispatch(setProductWorkflow(data));
	// 				} else {
	// 					showErrorMessage(res.data.message, 'error');
	// 				}
	// 			})
	// 			.catch((err) => {
	// 				dispatch(setAppLoading(false));
	// 				showErrorMessage('Sorry! An error occured ', 'error');
	// 				console.log('Error Log:', err);
	// 			});
	// 	}
	// };

	// useEffect(() => {
	// 	if (selectedProduct?.workflowId) {
	// 		getProductWorkflow(selectedProduct?.workflowId);
	// 	}
	// }, [selectedProduct]);

	const getComponent = (controlType, control, index) => {
		const Control = editorControls[controlType + 'Control'];

		if (!Control) return <>{`${controlType} not found !`}</>;

		return (
			<Control
				key={index}
				{...{
					index: index,
					data: control,
				}}
			/>
		);
	};

	let addMutation = useMutation(AddItemToCartNew, {
		onSuccess: (data) => {
			queryClient.invalidateQueries('cartItems');
			showSuccessMessage('Item added successfully', 'success');
			dispatch(setAppLoading(false));
			storeItem('orderID', data);
			setTimeout(() => {
				return (window.location.href = '/choose-payment');
			}, 1000);
		},
		onError: (data) => {
			showErrorMessage('Item could not be added', 'error');
			dispatch(setAppLoading(false));
		},
	});

	const addItemToCart = (payload) => {
		dispatch(setAppLoading(true));
		AddItemToCart({...payload})
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					showSuccessMessage(res.data.message, 'success');
					storeItem('orderID', res.data.data);
					setTimeout(() => {
						return (window.location.href = '/choose-payment');
					}, 1000);
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});

		// addMutation.mutate({...payload});
	};

	const preOrder = (payload) => {
		dispatch(setAppLoading(true));
		SavePreorder({...payload})
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					dispatch(setPaymentIsSuccessful(true));
					storeItem('orderID', res.data.data);
					navigate('/payment-status');
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	const buyNow = (payload) => {
		dispatch(setAppLoading(true));
		BuyNow({...payload})
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					showSuccessMessage(res.data.message, 'success');
					storeItem('orderID', res.data.data);
					setTimeout(() => {
						return (window.location.href = '/choose-payment');
					}, 1000);
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	// const onSubmitHandler = (event) => {
	// 	event.preventDefault();
	// 	dispatch(setAppLoading(true));

	// 	const serializedForm = formToJSON(event.target);
	// 	const payload = {
	// 		userId: getItem('u_msisdn'),
	// 		pricingId: getItem('selectedPricing'),
	// 		quantity: 1,
	// 		discountAmount: 0,
	// 		workflowData: [...serializedForm?.response],
	// 	};

	// 	if (getItem('purchaseMethod') === 'cart') {
	// 		addItemToCart(payload);
	// 	} else {
	// 		buyNow(payload);
	// 	}
	// };

	function flattenControls(components) {
		return components.reduce((accumulator, component) => {
			// Extract controls from the current component
			const controls = component?.controls?.map((x) => (x = {...x, orderIndex: component?.orderIndex})) || []; // add parent orderIndex

			// Flatten the controls and add them to the accumulator array
			accumulator.push(...controls);

			return accumulator;
		}, []);
	}

	const onBeforeNext = (stage, onBeforeNextdata) => {
		const currentStage = stage + 1; //store current stage so you can validate each control in their respective workflow component order indexes
		// console.log('onBeforeNextdata', onBeforeNextdata?.response);
		const flattenedControls = flattenControls(workflowQuery?.data?.data?.components);
		const foundControls = onBeforeNextdata?.response?.map((x) => {
			x = {...flattenedControls?.find((fc) => fc.id === x.id), ...x};
			return x;
		});

		// console.log('foundControls', foundControls);
		// console.log('currentStage', currentStage);
		// console.log('foundControls?.filter===', foundControls?.filter((x) => x?.orderIndex === currentStage));

		const errorMessages = [];
		const fieldErrors = [];
		for (const control of foundControls?.filter((x) => x?.orderIndex === currentStage)) {
			//handle validations based on the current stage
			if (control.required) {
				const isInvalidMessage = validator([{rule: 'empty', message: `${control?.displayName} is required`}], control?.value);

				if (isInvalidMessage) {
					errorMessages.push(isInvalidMessage);
					fieldErrors.push(control);
				}
			}
		}

		if (errorMessages.length > 0) {
			for (const errorMsg of errorMessages) {
				// toast.error(errorMsg);
				dispatch(setFieldErrorMessages([...fieldErrors]));
			}
			return false;
		}

		return true;
	};

	const onInit = (e) => {
		formWizard = e;
	};

	const cleanWorkflowData = (data) => {
		return data.map((item) => {
			return {
				...item,
				meta: item.meta ? JSON.stringify(item.meta) : '',
			};
		});
	};

	const onSubmit = (stage, data) => {
		let filteredResponse = data?.response?.filter((element) => element !== null);
		let cleanedResponse = cleanWorkflowData(filteredResponse);

		let payload = {
			userId: getItem('u_msisdn'),
			pricingId: getItem('selectedPricing'),
			quantity: 1,
			discountAmount: 0,
			workflowData: cleanedResponse,
		};

		if (purchaseForGroup?.GroupPurchase) {
			payload = {
				...payload,
				...purchaseForGroup,
			};
		}

		if (getItem('purchaseMethod') === 'preOrder') {
			preOrder(payload);
		} else if (getItem('purchaseMethod') === 'cart') {
			addItemToCart(payload);
		} else {
			buyNow(payload);
		}
	};

	const ReturnButton = (props) => {
		const handleNavigation = () => {
			if (formWizard.current() === 0) {
				navigate(-1);
			} else {
				formWizard.prev();
			}
		};

		return (
			<button {...props} onClick={handleNavigation} className='returnButton'>
				BACK
			</button>
		);
	};

	// const handleNext = () => {
	// 	formWizard.next();
	// 	setCurrentTab(currentTab + 1);
	// };
	let controlIndex = -1;

	return (
		<>
			<PageTitle title='Business Hub - Beneficiary' />

			{workflowQuery?.isLoading ? (
				<div className='grid place-items-center h-[500px]'>Loading ...</div>
			) : (
				<div className='min-h-[533px] lg:min-h-screen bg-[white] px-[17px] py-10 lg:mx-10'>
					<ReturnButton />

					<FormWizard formId='beneficiary-form' className='w-full lg:w-[50%]' stageHiddenCls='hidden' stageVisibleCls='' beforeNext={onBeforeNext} onInit={onInit} onSubmit={onSubmit} enableIndicator={false} currentStage={currentTab}>
						{workflowQuery?.data?.data?.components &&
							workflowQuery?.data?.data?.components
								?.sort((a, b) => a.orderIndex - b.orderIndex)
								?.map((component, i) => (
									<WizardStage key={i}>
										<h1 className='text-[#1c1c1c] font-bold text-[25px] mt-[26px] mb-1'>{component?.componentName}</h1>
										<p className='font-medium'>{component?.description}</p>

										{component?.controls?.map((control, index) => {
											controlIndex += 1;
											return getComponent(control?.type, control, controlIndex);
										})}
										{component?.cta === true ? (
											<button
												disabled={disableWorkflowCta}
												type='submit'
												className={`rounded-full w-full p-3 mt-8
										  ${disableWorkflowCta ? 'bg-gray-300 cursor-not-allowed' : 'bg-[#ffcc00] hover:bg-[#ffcc008c]'}
										  ${component?.componentName?.toLowerCase() === 'nearest branches' && 'hidden'}
										  ${workflowQuery?.data?.data?.components.length === i + 1 ? 'form-wizard-submit' : 'form-wizard-next'}`}
											>
												{/* {workflowQuery?.data?.data?.components.length === i + 1 ? 'Submit' : 'Continue'} */}
												{component?.ctaText || 'Submit'}
											</button>
										) : (
											''
										)}
										{disableWorkflowCta && (
											<button onClick={() => navigate('/')} className='rounded-full w-full p-3 mt-8 bg-[#ffcc00] hover:bg-[#ffcc008c]'>
												Return to Homepage
											</button>
										)}
									</WizardStage>
								))}
					</FormWizard>
				</div>
			)}
		</>
	);
};

export default SelectBeneficiary;
