import PickupLocationComponent from '../../pickupLocation/PickupLocationComponent';

function PickupLocationFieldEntry({data, index}) {
	return (
		<>
			<PickupLocationComponent data={data} index={index} />
		</>
	);
}

export default PickupLocationFieldEntry;
