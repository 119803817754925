import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	selectedProduct: {},
	productPackages: [],
	productWorkflow: {},
	paymentIsSuccessful: false,
	paymentErrorMessage: '',
	selectedGroup: {},
	selectedMember: {},
	selectedMsisdn: [],
	purchaseForGroup: {
		GroupPurchase: false,
		GroupId: '',
		GroupMembers: [],
	},
	disableWorkflowCta: false,
	fieldErrorMessages: [],
};

export const productSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {
		setSelectedProduct: (state, action) => {
			state.selectedProduct = action.payload;
		},
		setProductPackages: (state, action) => {
			state.productPackages = action.payload;
		},
		setProductWorkflow: (state, action) => {
			state.productWorkflow = action.payload;
		},
		setPaymentIsSuccessful: (state, action) => {
			state.paymentIsSuccessful = action.payload;
		},
		setPaymentErrorMessage: (state, action) => {
			state.paymentErrorMessage = action.payload;
		},
		setSelectedGroup: (state, action) => {
			state.selectedGroup = action.payload;
		},
		setSelectedMember: (state, action) => {
			state.selectedMember = action.payload;
		},
		setSelectedMsisdn: (state, action) => {
			state.selectedMsisdn = action.payload;
		},
		setPurchaseForGroup: (state, action) => {
			state.purchaseForGroup = action.payload;
		},
		setDisableWorkflowCta: (state, action) => {
			state.disableWorkflowCta = action.payload;
		},
		setFieldErrorMessages: (state, action) => {
			state.fieldErrorMessages = action.payload;
		},
	},
});

export const {setSelectedProduct, setProductPackages, setProductWorkflow, setPaymentIsSuccessful, setPaymentErrorMessage, setSelectedGroup, setSelectedMember, setSelectedMsisdn, setPurchaseForGroup, setDisableWorkflowCta, setFieldErrorMessages} = productSlice.actions;

export default productSlice.reducer;
