import React, {useCallback, useEffect, useState} from 'react';
import './dynamicbundlecreation.scss';
import {getBenefits} from '../../../bundle-utilities';
import {defaultBenefitPercentages} from '../../../config';
import {useQuery} from 'react-query';
import {GetBundleBenefits} from '../../../api/products.service';
import {useAlertMessage} from '../../../hooks/useAlertMessage';
import {useSelector} from 'react-redux';

let benefitTimer;

const DynamicBundleCreation = ({data, index, items}) => {
	const [amount, setAmount] = useState(10);
	let [inputError, setInputError] = useState(false);
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const [sliderPercentages, setSliderPercentages] = useState({
		dataSlider: defaultBenefitPercentages.data,
		smsSlider: defaultBenefitPercentages.sms,
		voiceSlider: defaultBenefitPercentages.voice,
	});

	const validateAmount = (e) => {
		const value = Number(e.target.value);
		if (value < 10 || value > 350) {
			showErrorMessage('Please enter an amount between GHC 10 and GHC 350.', 'error');
			setAmount(0);
			setInputError(true);
		}
		setInputError(false);
	};

	const [dataBenefit, setDataBenefit] = useState('0 MB');
	const [voiceBenefit, setVoiceBenefit] = useState('0 MINs');
	const [smsBenefit, setSmsBenefit] = useState('0 SMS');

	const [dataPercentage, setDataPercentage] = useState(defaultBenefitPercentages.data);
	const [voicePercentage, setVoicePercentage] = useState(defaultBenefitPercentages.voice);
	const [smsPercentage, setSmsPercentage] = useState(defaultBenefitPercentages.sms);

	const fieldErrorMessages = useSelector((state) => state.product.fieldErrorMessages);
	const hasErrorMessage = fieldErrorMessages.some((item) => item.id === data?.id);

	const handleSliderProgress = useCallback(() => {
		for (let e of document.querySelectorAll('input[type="range"].slider-progress')) {
			e.style.setProperty('--value', e.value);
			e.style.setProperty('--min', e.min === '' ? '0' : e.min);
			e.style.setProperty('--max', e.max === '' ? '100' : e.max);
			e.addEventListener('input', () => e.style.setProperty('--value', e.value));
		}
	});

	const benefitsQuery = useQuery({
		retry: (count, err) => count < 3,
		staleTime: Infinity,
		queryKey: [dataPercentage, voicePercentage, smsPercentage, amount, 'benefitsQueryKey'],
		queryFn: () => GetBundleBenefits({amount, dataPercentage, voicePercentage, smsPercentage}).then((res) => res.data),
		enabled: false,
	});

	useEffect(() => {
		handleSliderProgress();
	}, [handleSliderProgress, sliderPercentages, amount]);

	useEffect(() => {
		clearTimeout(benefitTimer);
		benefitTimer = setTimeout(() => {
			benefitsQuery.refetch();
		}, 500);
	}, [dataPercentage, voicePercentage, smsPercentage, amount]);

	const handleOnSliderChange = (e) => {
		let {value, id} = e.target;
		value = Number(value);
		const newValues = {...sliderPercentages, [id]: value};
		const percentageLeft = (100 - value) / 2;

		const dataValue = Number(document.getElementById('dataSlider')?.value);
		const voiceValue = Number(document.getElementById('voiceSlider')?.value);
		const smsValue = Number(document.getElementById('smsSlider')?.value);

		// console.log(dataValue);
		// console.log(voiceValue);
		// console.log(smsValue);

		if (id === 'dataSlider') {
			if (voiceValue === 0) {
				newValues.smsSlider = 100 - value;
				newValues.voiceSlider = 0;
			} else if (smsValue === 0) {
				newValues.voiceSlider = 100 - value;
				newValues.smsSlider = 0;
			} else {
				newValues.smsSlider = percentageLeft;
				newValues.voiceSlider = percentageLeft;
			}
		}
		if (id === 'voiceSlider') {
			if (dataValue === 0) {
				newValues.smsSlider = 100 - value;
				newValues.dataSlider = 0;
			} else if (smsValue === 0) {
				newValues.dataSlider = 100 - value;
				newValues.smsSlider = 0;
			} else {
				newValues.smsSlider = percentageLeft;
				newValues.dataSlider = percentageLeft;
			}
		}
		if (id === 'smsSlider') {
			if (dataValue === 0) {
				newValues.voiceSlider = 100 - value;
				newValues.dataSlider = 0;
			} else if (voiceValue === 0) {
				newValues.dataSlider = 100 - value;
				newValues.voiceSlider = 0;
			} else {
				newValues.voiceSlider = percentageLeft;
				newValues.dataSlider = percentageLeft;
			}
		}
		setSliderPercentages(newValues);

		// const benefit = getBenefits(amount, newValues.dataSlider, newValues.smsSlider, newValues.voiceSlider);
		// setDataBenefit(benefit.data);
		// setVoiceBenefit(benefit.voice);
		// setSmsBenefit(benefit.sms);

		//slider positions
		if (id === 'dataSlider') {
			if (voiceValue === 0) {
				setDataPercentage(value);
				setVoicePercentage(0);
				setSmsPercentage(100 - value);
			} else if (smsValue === 0) {
				setDataPercentage(value);
				setVoicePercentage(100 - value);
				setSmsPercentage(0);
			} else {
				setDataPercentage(value);
				setVoicePercentage(percentageLeft);
				setSmsPercentage(percentageLeft);
			}
		}
		if (id === 'voiceSlider') {
			if (dataValue === 0) {
				setDataPercentage(0);
				setVoicePercentage(value);
				setSmsPercentage(100 - value);
			} else if (smsValue === 0) {
				setDataPercentage(100 - value);
				setVoicePercentage(value);
				setSmsPercentage(0);
			} else {
				setDataPercentage(percentageLeft);
				setVoicePercentage(value);
				setSmsPercentage(percentageLeft);
			}
		}
		if (id === 'smsSlider') {
			if (dataValue === 0) {
				setDataPercentage(0);
				setVoicePercentage(100 - value);
				setSmsPercentage(value);
			} else if (voiceValue === 0) {
				setDataPercentage(100 - value);
				setVoicePercentage(0);
				setSmsPercentage(value);
			} else {
				setDataPercentage(percentageLeft);
				setVoicePercentage(percentageLeft);
				setSmsPercentage(value);
			}
		}
	};

	//backend requirements
	//1. api provision for bundle configuration(get min&max)

	let tarrifs = {
		data: {
			id: 'dataSlider',
			value: dataPercentage,
			benefit: benefitsQuery?.data?.data?.data ?? 'Loading...',
		},
		voice: {
			id: 'voiceSlider',
			value: voicePercentage,
			benefit: benefitsQuery?.data?.data?.voice ?? 'Loading...',
		},
		sms: {
			id: 'smsSlider',
			value: smsPercentage,
			benefit: benefitsQuery?.data?.data?.sms ?? 'Loading...',
		},
	};

	return (
		<>
			<div className='bg-[#fff]'>
				<div className={`${inputError ? 'border border-red-600' : ''} justify-start  bg-[#f2f2f2] items-center flex text-lg w-full mt-[27px] mb-[50px] px-[25px] py-[11px] rounded-xl`}>
					GHc
					<input
						required
						onBlur={validateAmount}
						type='number'
						name='ent-amt'
						id='ent-amt'
						placeholder=''
						className={` bg-[#ffcc0000] text-lg w-full ml-1 mt-0 p-0 border-[none] outline-none`}
						min='10'
						max='350'
						onChange={(e) => {
							setAmount(e.target.value);
						}}
						value={amount}
					/>
				</div>

				<div>
					<div className='flex flex-col mt-[15px] '>
						{items &&
							items?.map((item, idx) => (
								<React.Fragment key={idx}>
									<input id={tarrifs[item?.toLowerCase()].id} onChange={handleOnSliderChange} value={tarrifs[item?.toLowerCase()]?.value} type='range' min='0' max='100' className='styled-slider slider-progress' disabled={benefitsQuery?.isLoading} />
									<input type='hidden' name={`response[${index}][value][${item?.toLowerCase()}Percentage]`} value={tarrifs[item?.toLowerCase()]?.value} />

									<div className='customFlex justifySpaceBetween'>
										<small className='font-bold text-[11px] mb-[19px]'>{item}</small>
										<small className='font-bold text-[11px] mb-[19px]'>{tarrifs[item?.toLowerCase()]?.benefit}</small>
									</div>
								</React.Fragment>
							))}
					</div>
				</div>
			</div>
			<p className='text-red-600 text-[12px] mb-5'>{hasErrorMessage && `${data?.displayName} is required`}</p>

			<input type='hidden' name={`response[${index}][value][amount]`} value={amount} />
			<input type='hidden' name={`response[${index}][meta]`} />
			<input type='hidden' name={`response[${index}][controlName]`} value={data?.displayName} />
			<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
			<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
		</>
	);
};

export default DynamicBundleCreation;
