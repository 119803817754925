function DateFieldView({data, index, value}) {
	const dateFieldStyles = {
		fontSize: '12px',
		color: '#141414c0',
	};

	return (
		<>
			<div className='flexColumn requestEntry_field_container'>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? <small className='requestEntry_label'>{data?.displayName}</small> : ''}
				<input style={dateFieldStyles} type={data?.options.filter((x) => x.key === 'ENABLE-TIME-PICKER')[0]?.value === 'true' ? 'datetime-local' : 'date'} className='requestEntry_formFields' readOnly={true} value={value?.value} />
			</div>
		</>
	);
}

export default DateFieldView;
