import React, {useState} from 'react';
import './cartcardcounter.scss';

function CartCardCounter() {
	const [counter, setCounter] = useState(1);
	const increaseCounter = () => setCounter(counter + 1);
	const decreaseCounter = () => setCounter(counter === 0 ? 0 : counter - 1);

	return (
		<>
			<div className='mt-4 border border-[#FFCA05] w-[68%] rounded-md customFlex justifyEvenly'>
				<small className='border-r border-[#FFCA05] pr-2 ' onClick={decreaseCounter}>
					-
				</small>
				<small className='mx-2'>{counter}</small>
				<small className='border-l border-[#FFCA05] pl-2 ' onClick={increaseCounter}>
					+
				</small>
			</div>
		</>
	);
}

export default CartCardCounter;
