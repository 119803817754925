import React from 'react';
import {ReactComponent as TwitterIcon} from '../../../assets/icons/twitter.svg';
import {ReactComponent as FacebookIcon} from '../../../assets/icons/facebook.svg';
import {ReactComponent as YoutubeIcon} from '../../../assets/icons/youtube.svg';
import {ReactComponent as InstagramIcon} from '../../../assets/icons/instagram.svg';
import './footer.scss';
import {useLocation} from 'react-router-dom';

const footerItems = ['Terms & Conditions', 'Privacy Policy', 'Help Center', 'Apps'];

function Footer() {
	const pathname = useLocation();
	const externalRoutes = ['/external/choose-payment', '/external/confirm-payment', '/external/payment-status'];
	const hideHeader = externalRoutes.includes(pathname?.pathname);
	return (
		<>
			{hideHeader ? null : (
				<>
					<div class=' bg-black py-10 px-3 '>
						<div class='centerItems'>
							<div class='flex'>
								<TwitterIcon class=' mx-2' />
								<YoutubeIcon class=' mx-2' />
								<InstagramIcon class=' mx-2' />
								<FacebookIcon class=' mx-2' />
							</div>
						</div>
						<div class='centerItems'>
							<div class=' customFlex'>
								{footerItems.map((item, key) => (
									<p class=' text-[#D7D7D7] text-[8px] mx-2 mt-5' key={key}>
										{item}
									</p>
								))}
							</div>
						</div>
						<p class=' text-[#7C7777] text-[9px] text-center mt-3.5'>© 2019 MTN Group Management Services (Pty) Ltd, All rights reserved</p>
					</div>
				</>
			)}
		</>
	);
}

export default Footer;
