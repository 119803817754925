import { getAxios, getConfig } from "../utils";

const apiAxios = () => getAxios(getConfig().apiBaseUrl.ordersBaseUrl);

export const GetCustomerOrderHistoryByMsisdn = async () => await apiAxios().post('/Order/GetCustomerOrderHistoryByMsisdn');
export const GetOrderById = async (id) => await apiAxios().post('/Order/GetCustomerOrderById', {id});
export const Getcustomerorderbyidshop = async (id) => await apiAxios().post('/Order/Getcustomerorderbyidshop', {id});


export const GetCustomerOrderByIdShopExternal = async (id) => await apiAxios().post('/Order/GetCustomerOrderByIdShopExternal', {id});
