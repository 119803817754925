import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {useAlertMessage} from '../../../hooks/useAlertMessage';
import {currency} from '../../../utils';
import '../../../views/Product/product.scss';
import {storeItem} from '../../../api/storage.service';
import {setSelectedProduct} from '../../../redux/product/productSlice';

const transformPricing = (selectedProduct) => {
	const _variations = [].concat(...selectedProduct?.productAttributes?.map((a) => a.variations.map((v) => (v = {...v, ['attributeId']: a.attributeId}))));
	const pricing = [...selectedProduct?.pricings];
	const pricingVariation = pricing?.map((p, i) => {
		const newVariation = [...p?.variation];
		const variationInfo = newVariation.map((v) => {
			return _variations.find((va) => va.variationId == v);
		});

		const updatedP = {...p, variationInfo};

		return updatedP;
	});

	return [].concat(...pricingVariation);
};

const getProductByGroups = (transformedPricing, groupPricingBy) => {
	let group = {};
	for (let i = 0; i < transformedPricing.length; i++) {
		const groupVariation = transformedPricing[i].variationInfo.filter((x) => groupPricingBy.includes(x.attributeId));

		const groupTitle = groupVariation.map((x) => x.variationName).join(' ');

		const groupIds = groupVariation.map((x) => x.variationId);

		group[groupTitle] = group[groupTitle] || [];
		for (const id of groupIds) {
			if (!group[groupTitle].includes(id)) group[groupTitle].push(id);
		}
	}
	return group;
};

const Variable = (props) => {
	const navigate = useNavigate();
	const productDetails = transformPricing(props?.selectedProduct);
	const priceGrouping = getProductByGroups(productDetails, props?.selectedProduct.groupPricingBy);
	const defaultPrice = Object.keys(priceGrouping)[0];
	const dispatch = useDispatch();
	const userState = useSelector((state) => state.user);
	const [showModal, setShowModal] = useState(false);
	const [pricings, setPricings] = useState([]);
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const {productId} = useParams();
	const [selectedBenefit, setSelectedBenefit] = useState([]);
	const [showPrice, setShowPrice] = useState(0);

	const selectedPricingRef = useRef();

	const getPrices = (groupTitle) => {
		const product = priceGrouping[groupTitle];

		if (product == null || product.length <= 0) return [];

		let hasGroupIds = (target) => product.every((pp) => target.map((o) => o.variationId).includes(pp));

		if (product.length == props?.selectedProduct?.productAttributes?.length) {
			const pricingInGroup = productDetails.filter((p) => hasGroupIds(p.variationInfo));

			let prices = [].concat(...pricingInGroup.map((x, i) => x.variationInfo.map((o) => ({...o, benefits: x.benefits, displayPrefix: false, price: x.cost, pricingId: x.pricingId}))));
			prices = prices.reduce(function (p, obj) {
				if (p.filter((x) => x.pricingId === obj.pricingId).length <= 0) p.push(obj);
				return p;
			}, []);
			return prices;
		} else {
			const pricingInGroup = productDetails.filter((p) => p.variationInfo.find((x) => product.includes(x.variationId)) != null);
			var prices = [].concat(...pricingInGroup.map((x, i) => x.variationInfo.filter((v) => !product.includes(v.variationId)).map((o) => ({...o, benefits: x.benefits, displayPrefix: true, price: x.cost, pricingId: x.pricingId}))));
			return prices;
		}
	};

	const navigationHandler = (path, purchaseMethod) => {
		if (!selectedPricingRef?.current?.value) return showErrorMessage('Please select a package', 'error');

		storeItem('AfterVerificationPath', path);
		storeItem('purchaseMethod', purchaseMethod);
		storeItem('selectedPricing', selectedPricingRef?.current?.value);
		dispatch(setSelectedProduct(props?.selectedProduct));

		if (userState?.user && userState?.userInformation?.userExists) {
			navigate('/select-beneficiary');
		} else {
			props?.onAuthenticateUser(true);
		}
	};

	const updateHandler = (e) => {
		const {value} = e.target;
		var prices = getPrices(value);
		setPricings([...prices]);
		setSelectedBenefit([]);
		let pricingDropdown = document.getElementById('pricingDropdown');
		if (pricingDropdown !== null) {
			pricingDropdown.value = '';
		}
	};

	useEffect(() => {
		var prices = getPrices(defaultPrice);
		setPricings([...prices]);
	}, [defaultPrice]);

	const handlePriceChange = (e) => {
		let {value} = e.target;
		if (value) {
			const selectedPrice = [...pricings].find((x) => x.pricingId === value);
			setSelectedBenefit([...selectedPrice.benefits]);
			setShowPrice(selectedPrice?.price);
		}
	};

	return (
		<>
			<div className=''>
				<div className='justify-between customFlex'>
					<p className='text-[29px] font-bold'>{showPrice > 0 && props?.selectedProduct?.displayPrice && currency + ' ' + showPrice}</p>
				</div>
				<div className='lg:flex lg:w-full lg:items-center lg:pt-10'>
					{props?.selectedProduct?.groupPricingBy?.length > 0 && (
						<div className='mt-[9px] p-2'>
							<select className='w-full border text-lg font-bold px-[15px] py-2 rounded-[11px] border-solid border-[black] outline-none' onChange={updateHandler} name='packageCategory'>
								{priceGrouping &&
									Object.keys(priceGrouping)?.map((category, i) => (
										<option className='text-[13px]' key={i} value={category}>
											{category}
										</option>
									))}
							</select>
						</div>
					)}

					<div className='mt-[9px] p-2'>
						<select className='w-full border text-lg font-bold px-[15px] py-2 rounded-[11px] border-solid border-[black] outline-none' ref={selectedPricingRef} name='price' onChange={(e) => handlePriceChange(e)} defaultValue={'--Select preferred price'} id='pricingDropdown'>
							<option className='text-[13px]' value={''}>
								--Select preferred price
							</option>
							{pricings &&
								pricings.map((price, index) => (
									<option key={index} value={price.pricingId}>
										{price.displayPrefix ? (
											<>
												{price.variationName} @ {currency} {price.price}
											</>
										) : (
											<>
												{currency} {price.price}
											</>
										)}
									</option>
								))}
						</select>
					</div>
				</div>

				<div className=''>
					{props?.isPreOrder() ? (
						<button className='addtocart !mt-6 hover:!bg-[#fc0] hover:!border-[#fc0] hover:!border-none' onClick={() => navigationHandler('/select-beneficiary', 'cart')}>
							Pre-Order
						</button>
					) : (
						<>
							<div className='lg:flex gap-4'>
								<button className='w-full text-lg font-bold bg-[#ffca05] border mt-[30px] p-3 rounded-[26px]  border-solid border-[#ffca05]' onClick={() => navigationHandler('/select-beneficiary', 'buy')}>
									Buy now
								</button>
								<button className='w-full text-lg font-bold bg-white border mt-[30px] p-3 rounded-[26px]  border-solid border-[black]' onClick={() => navigationHandler('/select-beneficiary', 'cart')}>
									Add to cart
								</button>
							</div>
						</>
					)}
				</div>
			</div>

			{selectedBenefit.length > 0 && (
				<div className='bg-[#f5f5f5] mt-[18px] p-[17px] rounded-[51px]'>
					<p className='text-[1.1rem] font-semibold mb-1.5'>About this item</p>
					<table className=' w-full'>
						<tbody>
							{selectedBenefit !== null &&
								selectedBenefit?.map((benefit, index) => (
									<tr key={index}>
										<td className='text-[#6c6b6b] px-0 py-1.5'>{benefit?.description}</td>
										<td className='text-[#6c6b6b] px-0 py-1.5 whitespace-nowrap'>{benefit?.value}</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			)}
		</>
	);
};

export default Variable;
