import React, {useState, useEffect, useRef} from 'react';
import './choosepayment.scss';
import {BsChevronDown, BsChevronUp} from 'react-icons/bs';
import {useNavigate} from 'react-router-dom';
import airtelIcon from '../../assets/img/ATM-Logo-01.png';
import masterCardIcon from '../../assets/img/masterpayment.png';
import momoIcon from '../../assets/img/momo.png';
import visaIcon from '../../assets/img/visapayment.jpg';
import vodaIcon from '../../assets/img/T-Cash-Red.png';
import editIcon from '../../assets/icons/edit.png';
import {ReactComponent as SecureIcon} from '../../assets/icons/security.svg';
import ReturnButton from '../../components/reusable/returnButton/ReturnButton';
import {GetPaymentSessionDetails, UpdatePaymentStatus, TriggerPayViaMOMO, CheckMomoPaymentStatus} from '../../api/payment.service';
import {useDispatch} from 'react-redux';
import {setPaymentErrorMessage, setPaymentIsSuccessful} from '../../redux/product/productSlice';
import {getItem, storeItem, destroyItem} from '../../api/storage.service';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {setAppLoading} from '../../redux/app/appSlice';
import {toast} from 'react-toastify';
import {isValidPhoneNumber} from '../../utils';
import LeavePageAlert from '../../hooks/LeavePageAlert';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';
import {Getcustomerorderbyidshop} from '../../api/orders.service';
import {useQuery} from 'react-query';
import {LoginPayment} from '../../api/utilities.service';

function ChoosePayment() {
	const navigate = useNavigate();
	const [navDropdown, setNavDropdown] = useState(false);
	const [fieldContent, setFIeldContent] = useState('MoMo');
	const [isCardPayment, setIsCardPayment] = useState(false);
	const [paymentSessionData, setPaymentSessionData] = useState({});
	const [showFailureModal, setShowFailureModal] = useState(false);
	const [paymentIsSuccess, setPaymentIsSuccess] = useState(false);
	const [orderId, setOrderId] = useState(getItem('orderID'));
	const [msisdn, setMsisdn] = useState(getItem('u_msisdn'));
	const [editPhone, setEditPhone] = useState(false);
	const [msisdnInputEnabled, setMsisdnInputEnabled] = useState(true);
	const dropdownRef = useRef(null);

	const summaryQuery = useQuery({
		retry: (count, err) => count < 3,
		// staleTime: Infinity,
		queryKey: ['OrderSummaryKey'],
		queryFn: () => Getcustomerorderbyidshop(orderId).then((res) => res.data),
		enabled: orderId?.length > 0,
	});

	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();

	const [fieldImg, setFIeldImg] = useState(momoIcon);

	const handleDropDownMenu = () => {
		setNavDropdown(!navDropdown);
	};

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
				setNavDropdown(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [dropdownRef]);

	window.errorCallback = function (error) {
		showErrorMessage('Payment Error', 'error');
		dispatch(setPaymentIsSuccessful(false));
		navigate('/payment-status');
	};

	window.cancelCallback = function () {
		showErrorMessage('Payment cancelled', 'error');
		dispatch(setPaymentIsSuccessful(false));
		navigate('/payment-status');
	};

	window.completeCallback = function (obj) {
		// verify  payment success from backend
		verifyPaymentStatus()
			.then((success) => {
				if (success) {
					//remove embeded payment widget
					var container = document.getElementById('embed-target');
					if (container) {
						container.innerHTML = '';
					}
					dispatch(setPaymentIsSuccessful(true));
					navigate('/payment-status');
				} else {
					showErrorMessage('Payment not succeeded.', 'error');
					dispatch(setPaymentIsSuccessful(false));
					dispatch(setPaymentErrorMessage('Payment Failed'));
					navigate('/payment-status');
				}
			})
			.catch((err) => {
				showErrorMessage('Could not verify payment success.', 'error');
				dispatch(setPaymentIsSuccessful(false));
				navigate('/payment-status');
				console.log('Error Log:', err);
			});
	};
	window.timeoutCallback = function (obj) {
		showErrorMessage('Payment timeout', 'error');
		dispatch(setPaymentIsSuccessful(false));
		navigate('/payment-status');
	};

	const dropDownItems = [
		{text: 'MoMo', img: momoIcon},
		{text: 'Visa', img: visaIcon},
		{text: 'Mastercard', img: masterCardIcon},
		{text: 'Telecel Cash', img: vodaIcon},
		{text: 'AT Money', img: airtelIcon},
	];

	const handleSelection = (selectedItem) => {
		let paySection = document.getElementById('pay_section');

		if (selectedItem.text == 'Mastercard' || selectedItem.text == 'Visa') {
			setIsCardPayment(true);
			if (paySection) {
				paySection.style.display = 'none';
			}
		} else {
			setIsCardPayment(false);
			var container = document.getElementById('embed-target');
			if (container) {
				container.innerHTML = '';
			}
			if (paySection) {
				paySection.style.display = 'flex';
			}
		}
		setFIeldContent(selectedItem.text);
		setFIeldImg(selectedItem.img);
		setNavDropdown(false);
	};

	async function verifyPaymentStatus() {
		let sessionData = getItem('sessionData');
		let response = await UpdatePaymentStatus({session: sessionData});
		//  clear order id
		destroyItem('orderID');
		return response.data.success;
	}

	function getSessionDetails() {
		loadSessionDetails({
			id: orderId,
		})
			.then((response) => {
				setPaymentSessionData(response.data.data);
				storeItem('sessionData', response.data.data);
			})
			.catch((err) => {
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	}

	const loadSessionDetails = async (payload) => await GetPaymentSessionDetails(payload);

	useEffect(() => {
		dispatch(setAppLoading(false));
		window.sessionStorage.clear();

		if (isCardPayment) {
			getSessionDetails();
		}
	}, [isCardPayment]);

	useEffect(() => {
		if (isCardPayment) {
			initialize();
		}
	}, [paymentSessionData]);

	function initialize() {
		try {
			let Checkout = window.Checkout;
			let [sessionId, versionNumber, merchantName, orderId] = window.atob(paymentSessionData).split('-');

			Checkout.configure({
				session: {
					id: sessionId,
					version: versionNumber,
				},
				interaction: {
					merchant: {
						name: merchantName,
					},
					displayControl: {
						billingAddress: 'HIDE',
					},
				},
			});
			Checkout.showEmbeddedPage('#embed-target');
		} catch (e) {
			console.error(e);
		}
	}

	function phoneInputOnChange(e) {
		setMsisdn(e.target.value);
	}

	function editPencilOnClick(e) {
		setMsisdnInputEnabled(!msisdnInputEnabled);
	}

	async function handlePhoneBlur() {
		if (msisdn !== getItem('u_msisdn')) {
			// Check if the phone number has been changed
			let loginPayload = {msisdn};
			dispatch(setAppLoading(true));
			let response = await LoginPayment(loginPayload);
			dispatch(setAppLoading(false));

			if (response.data.success) {
				storeItem('u_msisdn', msisdn);
				storeItem('isPaymentVerification', 'true');
				navigate('/verify-number');
			} else {
				showErrorMessage(response.data.message, 'error');
			}
		}
	}

	async function payOnClick(e) {
		if (isCardPayment) return;

		//check if phone number is valid else show toast error.
		if (!isValidPhoneNumber(msisdn)) {
			toast.error('A mobile money account may be either 10 (starting with 0) or 9 digits (starting with any digit other than 0)', {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			return;
		}

		dispatch(setAppLoading(true));

		let payLoad = {
			msisdn: `${msisdn}`,
			orderId: `${orderId}`,
		};

		triggerMomoPayment(payLoad)
			.then((response) => {
				dispatch(setAppLoading(false));
				if (response.data.success) {
					showSuccessMessage(response.data.message, 'success');
					navigate('/confirm-payment');
				} else {
					showErrorMessage(response.data.message, 'error');
				}
			})
			.catch((err) => {
				// alert('Could not trigger momo');
				showErrorMessage('An Error occured !', 'error');
				console.log('Error Log:', err);
				dispatch(setAppLoading(false));
			});
	}

	const triggerMomoPayment = async (payLoad) => await TriggerPayViaMOMO(payLoad);

	const orderTotal = summaryQuery?.data?.data?.reduce((accumulator, currentItem) => {
		return accumulator + currentItem.cost * currentItem.quantity;
	}, 0);

	return (
		<>
			<PageTitle title='Business Hub - Payment Method' />

			<div className='bg-white min-h-[500px] pt-10 pb-0 px-5 md:px-14 md:grid grid-cols-2'>
				<div className='md:px-10'>
					<ReturnButton />
					<LeavePageAlert />
					<h1 className=' text-[#1c1c1c] font-bold text-[25px] textCenter mt-5'>Choose payment method</h1>
					<p className='text-center text-sm mt-2.5'>Please ensure that the number displayed below is where payment will be deducted</p>

					<div className='border cursor-pointer mt-[34px] p-[13px] rounded-xl border-solid border-[#c4c4c4] outline-none customFlex justifySpaceBetween' onClick={handleDropDownMenu}>
						<div className='customFlex'>
							<img src={fieldImg} className='w-6 h-6 object-contain mr-[15px] rounded-[100%]' alt='payment' />
							{fieldContent}
						</div>

						<span>{navDropdown ? <BsChevronUp /> : <BsChevronDown />}</span>
					</div>
					<div ref={dropdownRef} className={navDropdown ? 'relative flex flex-col shadow-[1px_1px_34px_lightgrey] mt-5 pb-0 px-[23px] py-[7px] rounded-[14px] bg-white ' : 'hidden'} id='customDropdown'>
						{dropDownItems.map((item, idx) => (
							<div onClick={() => handleSelection(item)} key={idx} id={item.text} className='customFlex px-0  border-b-[#E4E4E4] border-b border-solid cursor-pointer'>
								<span>
									<img className=' w-12 h-12 object-contain mr-[15px]' src={item.img} alt='payment' />
								</span>
								{item.text}
							</div>
						))}
					</div>

					{isCardPayment ? (
						<div id='embed-target'></div>
					) : (
						<div className='customFlex justifySpaceBetween  border cursor-pointer mt-[34px] p-[13px] rounded-xl border-solid border-[#c4c4c4]'>
							<input className='w-full' type='number' onChange={phoneInputOnChange} value={msisdn} disabled={msisdnInputEnabled} onBlur={handlePhoneBlur} />
							<span>
								<img className=' w-[17px]' src={editIcon} onClick={editPencilOnClick} alt='edit' />
							</span>
						</div>
					)}

					<p className='text-[#797979] text-[13px] text-center mb-0 mx-0 my-10 hidden md:block'>
						Rest assured your transaction is safe. This<br></br> technology safeguards your personal information and guarantees privacy.
					</p>
					<div id='' className='bg-white shadow-[1px_1px_24px_3px_lightgrey] px-4 py-3 rounded-t-[34px] mt-14 hidden md:flex'>
						<div className='text-[#797979] text-xs px-5 py-0 customFlex'>
							<SecureIcon className='mr-2' />
							Secure <br></br>Checkout
						</div>
						<button className='hover:bg-[#ffcc00a8] w-full bg-[#ffcc00] text-base font-bold mt-[15px] mb-2.5 p-3 rounded-[26px] border-[none]' onClick={payOnClick}>
							Pay
						</button>
					</div>
				</div>
				<div className='md:mt-0 mt-12'>
					<div className='bg-[#EDEDED] py-7 px-12 min-h-[500px]'>
						{summaryQuery?.isLoading ? (
							<div className='grid place-items-center min-h-[400px]'>
								<p>Please Wait...</p>
							</div>
						) : (
							<>
								<h1 className='mt-6 text-[22px] font-bold'>
									<i>Order Summary</i>
								</h1>
								<table className='w-full mt-3 text-sm border-spacing-[12px] border-separate'>
									{summaryQuery?.data?.data?.map((x, i) => (
										<React.Fragment key={i}>
											<tr>
												<td>{x?.productName}</td>
												<td className='text-right'>x{x?.quantity}</td>
											</tr>
											<tr>
												<td>Amount</td>
												<td className='text-right'>GHc {x?.cost}</td>
											</tr>
											<tr>
												<td>Total</td>
												<td className='text-right'>GHc {x?.quantity * x?.cost}</td>
											</tr>
											<tr>
												<td colSpan={2}>
													<div className='border-[0.5px] border-gray-300 my-3 w-full'></div>
												</td>
											</tr>
										</React.Fragment>
									))}
								</table>

								<div className='flex items-center justify-between px-3'>
									<div className='font-bold text-sm'>
										<i>Order Total:</i>
									</div>
									<div className='font-bold'>GHc {orderTotal}</div>
								</div>
							</>
						)}
					</div>
					<p className='text-[#797979] text-[13px] text-center mb-0 mx-0 my-10 md:hidden block'>
						Rest assured your transaction is safe. This<br></br> technology safeguards your personal information and guarantees privacy.
					</p>
					<div className=''>
						<div id='' className='bg-white shadow-[1px_1px_24px_3px_lightgrey] px-4 py-3 rounded-t-[34px] mt-14 md:hidden flex'>
							<div className='text-[#797979] text-xs px-5 py-0 customFlex'>
								<SecureIcon className='mr-2' />
								Secure <br></br>Checkout
							</div>
							<button className='hover:bg-[#ffcc00a8] w-full bg-[#ffcc00] text-base font-bold mt-[15px] mb-2.5 p-3 rounded-[26px] border-[none]' onClick={payOnClick}>
								Pay
							</button>
						</div>
					</div>
				</div>
			</div>

			{showFailureModal && (
				<div className='modal-success' onClick={() => setShowFailureModal(false)}>
					<div className='modal-success-body'>
						<div className='margin-bottom'>
							<div className='modal-success-title'>Payment Failed</div>
						</div>
						<div className='center-row align-center'>
							<span className='text-[12px] text-gray-600'>Y’ello, your account could not be debited. Kindly confirm you have sufficient funds on your account and pay for your order.</span>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default ChoosePayment;
