function NumberFieldView({data, index, value}) {
	return (
		<>
			{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? <small className='requestEntry_label'>{data?.displayName}</small> : ''}
			<div className='flexColumn requestEntry_field_container'>
				<input type='text' className='text-[13px] w-full my-[10px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' placeholder={data?.options.filter((x) => x.key === 'PLACEHOLDER')[0]?.value} value={value?.value} readOnly={true} />
				<small className='requestEntry_label'>{data?.displayName}</small>
			</div>
		</>
	);
}

export default NumberFieldView;
