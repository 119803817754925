import React from 'react';
import CartCardCounter from '../cartCardCounter/CartCardCounter';
import CartCheckBox from '../cartCheckBox/CartCheckBox';
import SMEImg from '../../../assets/img/SMEProductImg.jpg';
import './cartcardcheckbox.scss';
import {useAlertMessage} from '../../../hooks/useAlertMessage';
import {RemoveItemFromCart} from '../../../api/cart.service';
import {useMutation, useQueryClient} from 'react-query';
import {useDispatch} from 'react-redux';
import {setAppLoading} from '../../../redux/app/appSlice';

const CartCardCheckbox = (props) => {
	let discount = Math.floor((props?.item?.discountAmount / props?.item?.cost) * 100);
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const deleteMutation = useMutation(RemoveItemFromCart, {
		onSuccess: () => {
			queryClient.invalidateQueries('cartItems');
			showSuccessMessage('Item removed successfully', 'success');
			dispatch(setAppLoading(false));
		},
		onError: () => {
			showErrorMessage('Item could not be removed', 'error');
			dispatch(setAppLoading(false));
		},
	});

	const handleRemoveItem = () => {
		dispatch(setAppLoading(true));
		deleteMutation.mutate(props?.item?.orderItemId);
	};

	const handleCost = () => (props?.item?.quantity === 1 ? props?.item?.cost : props?.item?.packageTotal);

	return (
		<div className=''>
			<div className=' flex items-center justify-center'>
				<CartCheckBox checked={props?.item?.checked} item={props.item} />
				<div className='bg-white mb-6 pt-[15px] rounded-[30px] shadow-lg'>
					<div className='customFlex justifyEvenly py-0 p-7'>
						<img src={SMEImg} alt='cart image' className='rounded-2xl max-w-[122px] w-[100px]' />
						<div className='pl-5'>
							{/* <p className="cartCard__Details__brand">Apple</p> */}
							<p className='text-[13px] font-thin m-0'>{props?.item?.productName}</p>
							<p className='text-2xl font-bold m-0'>{`GHc ${handleCost()}`} </p>

							{props?.item?.discountAmount > 0 ? (
								<p className='text-sm font-thin m-0'>
									<strike>{discount}</strike>({`${props?.item?.discountAmount}`} off)
								</p>
							) : (
								''
							)}
							{props?.item?.productType?.toLowerCase() === 'virtual' ? <small>Qty: {props?.item?.quantity}</small> : <CartCardCounter />}
						</div>
					</div>
					<div className='w-full text-center text-[11.3px] mt-[26px] customFlex'>
						<div className='flex-[0.5] pl-[30px] p-4 border-r-[#F2F2F2] border-t-[#F2F2F2] border-t border-solid border-r hover:bg-[rgba(211,211,211,0.393)] hover:rounded-bl-[30px] cursor-pointer' onClick={() => console.log('Added to wishlist')}>
							Add to wishlist
						</div>
						<div className='flex-[0.5] p-4 border-t-[#F2F2F2] border-t border-solid hover:bg-[rgba(211,211,211,0.393)] hover:rounded-br-[30px] cursor-pointer' onClick={() => handleRemoveItem()}>
							Remove
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CartCardCheckbox;
