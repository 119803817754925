function EmailFieldView({data, index, value}) {
	return (
		<>
			<div className='flexColumn requestEntry_field_container'>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? <small className='requestEntry_label'>{data?.displayName}</small> : ''}

				<input type='email' className='requestEntry_formFields' value={value?.value} readOnly={true} />
			</div>
		</>
	);
}

export default EmailFieldView;
