import {RadioGroup} from '@headlessui/react';
import {useEffect, useState} from 'react';
import IndividualComponent from './individual/_component';
import GroupComponent from './group/_component';
import {CheckIcon} from '../../icons/CheckIcon';
import {UncheckIcon} from '../../icons/UncheckIcon';
import {GroupIcon} from '../../icons/GroupIcon';
import {IndividualIcon} from '../../icons/IndividualIcon';
import {useDispatch, useSelector} from 'react-redux';
import {setPurchaseForGroup, setSelectedMsisdn} from '../../../../redux/product/productSlice';
import { Asterisk } from '../../asterisk/Asterisk';

function BeneficiaryFieldEntry({data, index}) {
	const items = data?.options.filter((x) => x.key == 'INDIVIDUAL_LABEL' || x.key == 'GROUP_LABEL') ?? [];
	const [selected, setSelected] = useState(items[0]);
	const selectedPhoneNumbers = useSelector((state) => state?.product?.selectedMsisdn);
	const purchaseForGroup = useSelector((state) => state.product.purchaseForGroup);
	const separator = ';;';
	const [individualPhoneNumber, setIndividualPhoneNumber] = useState();
	const dispatch = useDispatch();
	const isFieldRequired = data?.required;
	const fieldErrorMessages = useSelector((state) => state.product.fieldErrorMessages);
	const hasErrorMessage = fieldErrorMessages.some((item) => item.id === data?.id);

	const icons = {
		group: <GroupIcon className='w-5 h-5' />,
		individual: <IndividualIcon className='w-5 h-5' />,
	};

	useEffect(() => {
		// reset states to default
		dispatch(setSelectedMsisdn([]));
		dispatch(
			setPurchaseForGroup({
				GroupPurchase: false,
				GroupId: '',
				GroupMembers: [],
			}),
		);
	}, [selected]);

	const handleOnChange = (e) => {
		setSelected(e);
	};

	return (
		<>
			<div className='mt-7'>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? <small className='requestEntry_label'>{data?.displayName} {isFieldRequired && <Asterisk />}</small> : ''}
				<RadioGroup value={selected} onChange={handleOnChange}>
					<div className='space-y-3'>
						{[...items].map((_item) => (
							<RadioGroup.Option key={_item?.value} value={_item} className={({active, checked}) => ` border border-slate-300 bg-white text-black relative flex cursor-pointer rounded-lg px-5 py-3 focus:outline-none`}>
								{({active, checked}) => (
									<>
										<div className='flex w-full items-center justify-between'>
											<div className='flex items-center gap-1'>
												<div className='flex items-center'>
													<div className='font-bold flex items-center gap-2'>
														<div className='bg-[#fc0] rounded-full p-1'>{icons[_item?.value?.toLowerCase()]}</div>
														<RadioGroup.Label as='p' className='font-bold text-sm'>
															{_item?.value}
														</RadioGroup.Label>
													</div>
												</div>
											</div>

											{checked ? (
												<div className='shrink-0 text-white'>
													<CheckIcon className='h-6 w-6' />
												</div>
											) : (
												<div className='shrink-0 text-white'>
													<UncheckIcon className='h-6 w-6' />
												</div>
											)}
										</div>
									</>
								)}
							</RadioGroup.Option>
						))}
					</div>
				</RadioGroup>

				<hr className='my-10'></hr>

				<div className=''>{selected?.value.toLowerCase() === 'individual' ? <IndividualComponent setIndividualPhoneNumber={setIndividualPhoneNumber} /> : <GroupComponent />}</div>
				<p className='text-red-600 text-[12px] mb-5'>{hasErrorMessage && `${data?.displayName} is required`}</p>

			</div>
			{/* {console.log(selected?.value.toLowerCase() === 'individual' ? individualPhoneNumber : selectedPhoneNumbers?.join(separator))} */}
			<input type='hidden' name={`response[${index}][meta]`} />
			<input type='hidden' name={`response[${index}][controlName]`} value={data?.displayName} />
			<input type='hidden' name={`response[${index}][value]`} value={selected?.value.toLowerCase() === 'individual' ? individualPhoneNumber : '0540000000'} />
			<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
			<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
		</>
	);
}

export default BeneficiaryFieldEntry;
