import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './cart.scss';
import CartCardCheckbox from '../../components/reusable/cartCardCheckbox/CartCardCheckbox';
import {ReactComponent as CartImage} from '../../assets/img/cart.svg';
import {ReactComponent as ForwardLeaningQuestionMark} from '../../assets/img/forwardLeaningQuestionMark.svg';
import {ReactComponent as BackwardLeaningQuestionMark} from '../../assets/img/backwardLeaningQuestionMark.svg';
import ProductList from '../../components/reusable/productList/ProductList';
import ReturnButton from '../../components/reusable/returnButton/ReturnButton';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {Checkout, GetCustomerCart, SelectAllItemsInCart, UnCheckAllItemsInCart} from '../../api/cart.service';
import {useNavigate} from 'react-router-dom';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {setAppLoading} from '../../redux/app/appSlice';
import {getItem, storeItem} from '../../api/storage.service';

function Cart() {
	const user = useSelector((state) => state.user.user);
	const navigate = useNavigate();
	const [allSelected, setAllSelected] = useState(true);

	const {
		isLoading,
		isError,
		error,
		data: cartItems,
	} = useQuery({
		queryKey: ['cartItems'],
		queryFn: () => (user ? GetCustomerCart(getItem('u_msisdn')).then((res) => res.data.data) : null),
	});

	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	const checkAllItemMutation = useMutation(SelectAllItemsInCart, {
		onSuccess: () => {
			queryClient.invalidateQueries('cartItems');
			showSuccessMessage('All Items checked', 'success');
			dispatch(setAppLoading(false));
			setAllSelected(!allSelected);
		},
		onError: () => {
			showErrorMessage('Items could not be checked', 'error');
			dispatch(setAppLoading(false));
		},
	});

	const uncheckAllItemMutation = useMutation(UnCheckAllItemsInCart, {
		onSuccess: () => {
			queryClient.invalidateQueries('cartItems');
			showSuccessMessage('All Items unchecked', 'success');
			dispatch(setAppLoading(false));
			setAllSelected(!allSelected);
		},
		onError: () => {
			showErrorMessage('Items could not be unchecked', 'error');
			dispatch(setAppLoading(false));
		},
	});

	const handleSelectAllItems = () => {
		if (allSelected) {
			uncheckAllItemMutation.mutate(cartItems?.orderId);
		} else {
			checkAllItemMutation.mutate(cartItems?.orderId);
		}
	};

	const handleCheckout = () => {
		dispatch(setAppLoading(true));
		Checkout({userId: getItem('u_msisdn')})
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					showSuccessMessage(res.data.message, 'success');
					storeItem('orderID', res.data.data);
					setTimeout(() => {
						return navigate('/choose-payment');
					}, 1000);
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	return (
		<>
			{cartItems?.productDetails?.length === 0 ? (
				<EmptyCart />
			) : (
				<>
					<div className='py-10 px-[30px] pb-3 bg-white '>
						<ReturnButton />
						<p className='text-[27px] font-bold mb-1.5'>Your Cart ({cartItems?.productCount ?? 0})</p>
						<small className='underline cursor-pointer hover:font-bold' onClick={() => handleSelectAllItems()}>
							{allSelected ? 'Unselect all items' : 'Select all items'}
						</small>
						<div className='mt-16 lg:grid grid-cols-3'>
							{cartItems?.productDetails?.map((item, index) => (
								<CartCardCheckbox key={item?.packageId} item={item} />
							))}
						</div>
					</div>
					<div className=' bg-white py-10 px-9 text-end'>
						<small className='font-bold text-[#898989]'>SUBTOTAL OF ITEMS ({cartItems?.totalQuantity})</small>
						<p className='text-2xl font-bold mt-2 mb-5'>GHc {cartItems?.totalCost}.00</p>
						<button className='w-full mt-4 p-3 rounded-[26px] bg-[#FFCC00] mb-6 text-lg font-bold lg:w-[310px]' onClick={() => handleCheckout()}>
							Checkout
						</button>
						<div className='h-[0.5px] w-full bg-gray-300 my-[11px]'></div>
					</div>
					<div className='bg-[white] px-5 py-[70px]  lg:px-10'>
						<p className='!text-2xl lg:text-lg lg:font-medium '>Suggested Products</p>
						<ProductList />
					</div>
				</>
			)}
		</>
	);
}

function EmptyCart() {
	return (
		<>
			<div className='pt-10 pb-3 px-[30px] bg-white'>
				<ReturnButton />
				<div className='items-center flex flex-col justify-center'>
					<div>
						<ForwardLeaningQuestionMark />
						<BackwardLeaningQuestionMark />
					</div>
					<CartImage className='mb-5' />
					<h1 className='text-[25px] mb-5 font-bold'>Sorry, empty cart</h1>
					{/* <Button text={"Start Shopping"} /> */}
				</div>
			</div>
			<div className='flex flex-1'></div>
			<div className='bg-[#898989] h-[0.5px] mx-[60px] my-0'></div>
			<div className='bg-[white] px-5 py-[70px]  lg:px-10'>
				<p className='!text-2xl '>Suggested Products</p>
				<ProductList />
			</div>
		</>
	);
}

export default Cart;
