import React from 'react';
import Slider from 'react-slick';
import {getItem} from '../../../api/storage.service';

const Carousel = ({carouselImages}) => {
	const imagePath = JSON.parse(getItem('appData'))?.apiBaseUrl?.assetsBaseUrl;

	var settings = {
		dots: true,
		infinite: true,
		speed: 1200,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 7000,
	};
	return (
		<Slider {...settings}>
			{carouselImages?.map((item, index) => (
				<div key={index} className='carousel-item active relative float-left w-full cursor-pointer' onClick={() => window.open(item?.permaLink, '_blank')}>
					{/* <img src={`${imagePath + '/' + item?.imageName}`} className='relative w-full max-h-[400px] md:max-h-[500px] object-contain md:object-cover' /> */}
					<div style={{backgroundImage: `url(${imagePath + '/' + item?.imageName})`}} className='relative z-50 bg-gray-200 bg-center bg-no-repeat bg-cover h-[450px] w-full hidden md:block'></div>
					<div style={{backgroundImage: `url(${imagePath + '/' + item?.mobileImage?.imageName})`}} className='relative z-50 bg-gray-200 bg-center bg-no-repeat bg-cover h-[450px] w-full md:hidden'></div>
				</div>
			))}
		</Slider>
	);
};

export default Carousel;
