import {useSelector} from 'react-redux';
import {Asterisk} from '../../asterisk/Asterisk';

{
	/* <input type='text' name='beneficiary-no' id='beneficiary-no' placeholder='Beneficiary number' className='sbeneficiary_field' /> */
}

function TextFieldEntry({data, index}) {
	const isFieldRequired = data?.required;
	const fieldErrorMessages = useSelector((state) => state.product.fieldErrorMessages);
	const hasErrorMessage = fieldErrorMessages.some((item) => item.id === data?.id);

	return (
		<>
			<div className='flexColumn '>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? (
					<small className='requestEntry_label'>
						{data?.displayName} {isFieldRequired && <Asterisk />}
					</small>
				) : (
					''
				)}
				<div className={'error-placeholder error-comp' + data?.componentId}></div>
				{data?.options.filter((x) => x.key === 'MULTI-LINE')[0]?.value === 'true' ? (
					<textarea
						className='text-[13px] w-full my-[10px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none '
						placeholder={data?.options.filter((x) => x.key === 'PLACEHOLDER')[0]?.value}
						name={`response[${index}][value]`}
						minLength={data?.options.filter((x) => x.key === 'MIN-LEN')[3]?.value}
						maxLength={data?.options.filter((x) => x.key === 'MAX-LEN')[1]?.value}
						// required={data?.required}
						pattern={data?.options.find((x) => x.key === 'REGEX')?.value}
					></textarea>
				) : (
					<input
						id={data?.displayName?.toLowerCase()?.includes('fullname') ? 'fullname' : 'textfield'}
						type='text'
						className='text-[13px] w-full my-[10px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none '
						placeholder={data?.options.filter((x) => x.key === 'PLACEHOLDER')[0]?.value}
						name={`response[${index}][value]`}
						minLength={data?.options.filter((x) => x.key === 'MIN-LEN')[3]?.value}
						maxLength={data?.options.filter((x) => x.key === 'MAX-LEN')[1]?.value}
						// required={data?.required}
						pattern={data?.options.find((x) => x.key === 'REGEX')?.value}
					/>
				)}
				<p className='text-red-600 text-[12px] mb-5'>{hasErrorMessage && `${data?.displayName} is required`}</p>
				<input type='hidden' name={`response[${index}][controlName]`} value={data?.displayName} />
				<input type='hidden' name={`response[${index}][meta]`} className='text-[13px] w-full mt-[22px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none ' />
				<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} className='text-[13px] w-full mt-[22px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none ]' />
				<input type='hidden' name={`response[${index}][id]`} value={data?.id} className='text-[13px] w-full mt-[22px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none ' />
			</div>
		</>
	);
}

export default TextFieldEntry;
