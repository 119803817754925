import React from 'react';
import {storeItem} from '../../../api/storage.service';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {currency} from '../../../utils';
import {setSelectedProduct} from '../../../redux/product/productSlice';

const ExternalVar = (props) => {
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();

	return (
		<>
			<div className='lg:hidden'>
				<div className='justify-between customFlex'>
					<p className='text-[29px] font-bold'>{props?.selectedProduct?.pricings?.length > 0 && props?.selectedProduct?.displayPrice && currency + ' ' + props?.selectedProduct?.pricings[0]?.cost}</p>
				</div>
				<div className=''>
					<a href={props?.selectedProduct?.externalProductLink}>
						<button className='w-full text-lg font-bold bg-[#ffca05] border mt-[30px] p-3 rounded-[26px]  border-solid border-[#ffca05]'>Buy now</button>
					</a>

					{/* <button className='addtocart' onClick={() => navigationHandler('/select-beneficiary', 'cart')}>
						Add to cart
					</button> */}
				</div>
				{props?.selectedProduct?.pricings?.length > 0 && (
					<div className='bg-neutral-100 rounded-[51px] mt-[18px] p-[17px]'>
						<p className='text-[1.1rem] font-semibold mb-1.5'>About this item</p>
						<table className='w-full'>
							<tbody>
								{props?.selectedProduct?.pricings[0]?.benefits !== null &&
									props?.selectedProduct?.pricings[0]?.benefits?.map((benefit, index) => (
										<tr key={index}>
											<td className='text-[#6c6b6b] px-0 py-1.5'>{benefit?.description}</td>
											<td className='whitespace-nowrap text-[#6c6b6b] px-0 py-1.5'>{benefit?.value}</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				)}
			</div>
			{/* desktop view */}
			<div className='hidden lg:block'>
				<div className='justify-between customFlex'>
					<p className='text-[29px] font-bold'>{props?.selectedProduct?.pricings?.length > 0 && props?.selectedProduct?.displayPrice && currency + ' ' + props?.selectedProduct?.pricings[0]?.cost}</p>
				</div>

				{props?.selectedProduct?.pricings?.length > 0 && (
					<div className='bg-neutral-100 rounded-[51px] mt-[18px] p-[17px]'>
						<p className='text-[1.1rem] font-semibold mb-1.5'>About this item</p>
						<table className='w-full'>
							<tbody>
								{props?.selectedProduct?.pricings[0]?.benefits !== null &&
									props?.selectedProduct?.pricings[0]?.benefits?.map((benefit, index) => (
										<tr key={index}>
											<td className='text-[#6c6b6b] px-0 py-1.5'>{benefit?.description}</td>
											<td className='whitespace-nowrap text-[#6c6b6b] px-0 py-1.5'>{benefit?.value}</td>
										</tr>
									))}
							</tbody>
						</table>
						<div className=''>
							<a href={props?.selectedProduct?.externalProductLink} className='flex justify-end'>
								<button className='w-1/2  text-lg font-bold bg-[#ffca05] border mt-[30px] p-3 rounded-[26px]  border-solid border-[#ffca05]'>Buy now</button>
							</a>

							{/* <button className='addtocart' onClick={() => navigationHandler('/select-beneficiary', 'cart')}>
						Add to cart
					</button> */}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default ExternalVar;
