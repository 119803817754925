import React, {useEffect, useState} from 'react';
import {ReactComponent as MtnLogo} from '../../../assets/img/mtn_logo_modal.svg';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PageTitle from '../../../components/reusable/pageTitle/PageTitle';

const ExternalPaymentStatus = () => {
	let productSlice = useSelector((state) => state.product);
	let [paymentSuccess, setPaymentSuccess] = useState(productSlice.paymentIsSuccessful);
	let [paymentErrorMessage, setPaymentErrorMessage] = useState(productSlice.paymentErrorMessage);
	const navigate = useNavigate();

	useEffect(() => {
		if (paymentSuccess) {
			window.localStorage.removeItem('orderID');
			window.localStorage.removeItem('u_msisdn');
		}
	}, [paymentSuccess]);

	return (
		<>
			<PageTitle title={`${paymentSuccess ? 'Business Hub - Payment Successful' : 'Business Hub - Payment Failed'}`} />

			<>
				<div className='md:h-[70vh] h-[100vh] grid place-items-center w-full md:w-1/2 mx-auto px-4 md:px-0'>
					<div className=''>
						<div className='flex justify-center items-center'>
							<MtnLogo className='' />
						</div>
						<p className='font-bold uppercase my-2 text-center'>{paymentSuccess ? 'order placed' : 'Payment Failed'}</p>
						<p className='font-bold text-[34px] mb-2 text-center'>{paymentSuccess && 'Success !'}</p>
						<p className='mb-11 text-gray-600 text-center'>{paymentSuccess ? 'Y’ello, Your payment has been processed successfully' : 'Y’ello, your account could not be debited. Kindly confirm you have sufficient funds on your account and pay for your order.'}</p>
						{!paymentSuccess && (
							<div className='flex justify-center items-center'>
								<button onClick={() => navigate('/external/choose-payment')} className='rounded-full w-full md:w-1/2 bg-[#ffcc00] p-3 font-bold'>
									Retry payment
								</button>
							</div>
						)}
					</div>
				</div>
			</>
		</>
	);
};

export default ExternalPaymentStatus;
