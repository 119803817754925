import {useState} from 'react';
import paperIcon from '../../../../assets/img/paper.png';

function FileUploadFieldView({data, index, value}) {
	const [uploadPath, setUploadPath] = useState();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [uploadBg, setUploadBg] = useState(null);
	const imageExtensions = ['jpg', 'jpeg', 'webp', 'png'];

	const separator = ';;';
	const images = () => value?.value?.split(separator);

	const fieldContainer = {
		fontSize: '12px',
		color: 'red !important',
		width: '289px',
		textAlign: 'left',
	};

	const hideInputField = {
		display: 'none',
	};

	const isImage = (url) => {
		var re = /(?:\.([^.]+))?$/;
		var ext = re.exec(url)[1]?.toLowerCase();
		return imageExtensions.includes(ext);
	};

	return (
		<>
			<div className='flexColumn requestEntry_field_container'>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? <small className='requestEntry_label'>{data?.displayName}</small> : ''}
				<ul style={{margin: 0, padding: 0}}>
					{images()?.map((file, i) => (
						<>
							{isImage(file) ? (
								<li key={i} className='customUploadButton' style={{backgroundImage: 'url(' + file + ')'}}>
									<span className='actionsStyle'>
										<a className='actionsHrefStyle' href={file}>
											View
										</a>
									</span>
								</li>
							) : (
								<li key={i} className='customUploadButton file' style={{backgroundImage: 'url(' + paperIcon + ')'}}>
									<span className='actionsStyle'>
										<a className='actionsHrefStyle' href={file}>
											Download
										</a>
									</span>
								</li>
							)}
						</>
					))}
				</ul>
			</div>
		</>
	);
}

export default FileUploadFieldView;
