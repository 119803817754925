import Entry from './entry';
import View from './view';

function TextLabelControl({view, data, index, value}) {
	if (view === 'view') {
		return <View data={data} value={value} />;
	}

	return <Entry data={data} index={index} />;
}
export default TextLabelControl;
