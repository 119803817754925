import { Asterisk } from "../../asterisk/Asterisk";
import { useSelector } from 'react-redux';

function DropdownListEntry({data, index}) {
	const separator = ';;';
	const items = () => data?.options?.filter((x) => x.key == 'ITEMS')[0].value.split(separator);
	const isFieldRequired = data?.required;
	const fieldErrorMessages = useSelector((state) => state.product.fieldErrorMessages);
	const hasErrorMessage = fieldErrorMessages.some(item => item.id === data?.id);

	return (
		<>
			<div className='flexColumn requestEntry_field_container'>
				{data?.options.filter((x) => x.key === 'SHOW_DISPLAY_NAME')[0]?.value === 'true' ? <small className={data?.required ? 'required requestEntry_label' : 'requestEntry_label'}>{data?.displayName} {isFieldRequired && <Asterisk />}</small> : ''}
				<div className={'error-placeholder error-comp-' + data?.componentId}></div>
				<select name={`response[${index}][value]`} className='flexColumn requestEntry_formFields text-[13px] w-full my-[10px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none ' id='select-inputField'>
					<option value=''>-- {data?.options.filter((x) => x.key === 'PLACEHOLDER')[0]?.value}</option>
					{items().map((item, i) => (
						<option value={item} key={i}>
							{item}
						</option>
					))}
				</select>
				<p className='text-red-600 text-[12px] mb-5'>{hasErrorMessage && `${data?.displayName} is required`}</p>

				<input type='hidden' name={`response[${index}][meta]`} />
				<input type='hidden' name={`response[${index}][controlName]`} value={data?.displayName} />
				<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
				<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
			</div>
		</>
	);
}

export default DropdownListEntry;
