
function PickupLocationField({ data, index, value }) {

    return (
        <>

        </>
    );
}

export default PickupLocationField;
