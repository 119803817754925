import React from 'react';
import WifiIcon from '../../assets/icons/wifi.png';
import BroadcastIcon from '../../assets/icons/broadcast (1).png';
import CloudIcon from '../../assets/icons/cloud.png';
import './home.scss';
import ProductList from '../../components/reusable/productList/ProductList';
import Carousel from '../../components/reusable/carousel/Carousel';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import {useDispatch} from 'react-redux';
import {GetHomepageSliders} from '../../api/utilities.service';
import {useQuery} from 'react-query';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';

function Home() {
	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();

	const carouselQuery = useQuery({
		retry: (count, err) => count < 3,
		staleTime: Infinity,
		queryKey: ['carouselQueryKey'],
		queryFn: () => GetHomepageSliders().then((res) => res?.data?.data),
		enabled: true,
	});

	// const getCarouselImages = () => {
	// 	dispatch(setAppLoading(true));
	// 	GetHomepageSliders()
	// 		.then((res) => {
	// 			dispatch(setAppLoading(false));
	// 			if (res.data.success) {
	// 				setCarouselImages(res.data.data);
	// 			} else {
	// 				showErrorMessage(res.data.message, 'error');
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			dispatch(setAppLoading(false));
	// 			showErrorMessage('Sorry! An error occured ', 'error');
	// 			console.log('Error Log:', err);
	// 		});
	// };

	// useEffect(() => {
	// 	getCarouselImages();
	// }, []);

	return (
		<>
			<PageTitle title='Business Hub - Home Page' />
			<div className='min-h-screen bg-[#f5f5f5]'>
				<div className=' flex items-center justify-center pt-3'>
					<div className='text-center py-2.5 px-6  mr-7 '>
						<img src={WifiIcon} className=' ml-1  w-8 object-contain' alt='Wifi Icon' />
						<p className='text-center text-xs'>Bundles</p>
					</div>
					<div className='text-center py-2.5 px-6  mr-1 '>
						<img src={BroadcastIcon} className=' ml-3  w-8 object-contain' alt='Broadcast Icon' />
						<p className='text-center text-xs'>Broadband</p>
					</div>
					<div className='text-center py-2.5 px-6  '>
						<img src={CloudIcon} className=' ml-7 w-8 object-contain' alt='Cloud Icon' />
						<p className='text-center text-xs'>Cloud & Converged</p>
					</div>
				</div>
				<div className=''>{carouselQuery?.isLoading ? <div className='h-[400px] grid place-items-center'>Loading ...</div> : <Carousel carouselImages={carouselQuery?.data} />}</div>
				<div className='py-10'>
					<p className=' flex items-center justify-center mb-5 text-2xl font-bold'>Recommended Products</p>
					<ProductList />
				</div>
			</div>
		</>
	);
}

export default Home;
