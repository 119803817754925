import React from 'react';
import {useNavigate} from 'react-router-dom';
import './ShareButtons.scss';
import {ReactComponent as FacebookIcon} from '../../../assets/icons/FacebookIcon.svg';
import {ReactComponent as TwitterIcon} from '../../../assets/icons/TwitterIcon.svg';

const ShareButtons = () => {
	return (
		<>
			<div className=' customFlex'>
				<p className='font-normal  mr-3.5 mt-2'>Share</p>
				<div className='flex mt-[9px] items-center'>
					<FacebookIcon className=' mr-[15px]' />
					<TwitterIcon className=' mr-[15px]' />
				</div>
			</div>
		</>
	);
};

export default ShareButtons;
