import React from 'react';
import {storeItem} from '../../../api/storage.service';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {currency} from '../../../utils';
import {setSelectedProduct} from '../../../redux/product/productSlice';

const Simple = (props) => {
	const navigate = useNavigate();
	const userState = useSelector((state) => state.user);
	const dispatch = useDispatch();

	const navigationHandler = (path, purchaseMethod, selectedPricing) => {
		storeItem('AfterVerificationPath', path);
		storeItem('purchaseMethod', purchaseMethod);
		storeItem('selectedPricing', selectedPricing);

		dispatch(setSelectedProduct(props?.selectedProduct));

		if (userState?.user && userState?.userInformation?.userExists) {
			navigate('/select-beneficiary');
		} else {
			props?.onAuthenticateUser(true);
		}
	};
	return (
		<>
			<div className='lg:hidden'>
				<div className=''>
					<div className='justify-between customFlex'>
						<p className='text-[29px] font-bold'>{props?.selectedProduct?.pricings?.length > 0 && props?.selectedProduct?.displayPrice && currency + ' ' + props?.selectedProduct?.pricings[0]?.cost}</p>
					</div>

					<div className=''>
						{props?.isPreOrder() ? (
							<button
								className=' w-full text-lg font-[bold] bg-white border  p-3 rounded-[26px] border-[none] border-solid border-[black] !mt-6 hover:!bg-[#fc0] hover:!border-[#fc0] hover:!border-none'
								onClick={() => navigationHandler('/select-beneficiary', 'preOrder', props?.selectedProduct?.pricings[0]?.pricingId)}
							>
								Pre-Order
							</button>
						) : (
							<>
								<button className='w-full text-lg font-bold bg-[#ffca05] border mt-[30px] p-3 rounded-[26px]  border-solid border-[#ffca05]' onClick={() => navigationHandler('/select-beneficiary', 'buy', props?.selectedProduct?.pricings[0]?.pricingId)}>
									Buy now
								</button>
							</>
						)}

						{/* <button className='addtocart' onClick={() => navigationHandler('/select-beneficiary', 'cart')}>
						Add to cart
					</button> */}
					</div>
				</div>

				{props?.selectedProduct?.pricings?.length > 0 && (
					<div className='bg-neutral-100 rounded-[51px] mt-[18px] p-[17px]'>
						<p className='text-[1.1rem] font-semibold mb-1.5'>About this item</p>
						<table className='w-full'>
							<tbody>
								{props?.selectedProduct?.pricings[0]?.benefits !== null &&
									props?.selectedProduct?.pricings[0]?.benefits?.map((benefit, index) => (
										<tr key={index}>
											<td className='text-[#6c6b6b] px-0 py-1.5'>{benefit?.description}</td>
											<td className='whitespace-nowrap text-[#6c6b6b] px-0 py-1.5'>{benefit?.value}</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				)}
			</div>
			{/* desktop view */}
			<div className='hidden lg:block'>
				<div className=''>
					<div className='justify-between customFlex'>
						<p className='text-[29px] font-bold'>{props?.selectedProduct?.pricings?.length > 0 && props?.selectedProduct?.displayPrice && currency + ' ' + props?.selectedProduct?.pricings[0]?.cost}</p>
					</div>
				</div>

				{props?.selectedProduct?.pricings?.length > 0 && (
					<div className='bg-neutral-100 rounded-[51px] mt-[18px] p-[17px]'>
						<p className='text-[1.1rem] font-semibold mb-1.5'>About this item</p>
						<table className='w-full'>
							<tbody>
								{props?.selectedProduct?.pricings[0]?.benefits !== null &&
									props?.selectedProduct?.pricings[0]?.benefits?.map((benefit, index) => (
										<tr key={index}>
											<td className='text-[#6c6b6b] px-0 py-1.5'>{benefit?.description}</td>
											<td className='whitespace-nowrap text-[#6c6b6b] px-0 py-1.5'>{benefit?.value}</td>
										</tr>
									))}
							</tbody>
						</table>
						<div className='flex justify-end'>
							<div className=''>
								{props?.isPreOrder() ? (
									<button
										className=' w-full text-lg font-[bold] bg-white border  p-3 rounded-[26px] border-[none] border-solid border-[black] !mt-6 hover:!bg-[#fc0] hover:!border-[#fc0] hover:!border-none'
										onClick={() => navigationHandler('/select-beneficiary', 'preOrder', props?.selectedProduct?.pricings[0]?.pricingId)}
									>
										Pre-Order
									</button>
								) : (
									<>
										<button className='w-full text-lg font-bold bg-[#ffca05] border mt-[30px] p-3 rounded-[26px]  border-solid border-[#ffca05]' onClick={() => navigationHandler('/select-beneficiary', 'buy', props?.selectedProduct?.pricings[0]?.pricingId)}>
											Buy now
										</button>
									</>
								)}

								{/* <button className='addtocart' onClick={() => navigationHandler('/select-beneficiary', 'cart')}>
						Add to cart
					</button> */}
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Simple;
