import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {ReactComponent as PhoneNumberInputIcon} from '../../assets/icons/phone-number-input-icon.svg';
import {ReactComponent as MsisdnIcon} from '../../assets/icons/phone-svgrepo-com.svg';
import {ReactComponent as SuitcaseIcon} from '../../assets/icons/suitcase-svgrepo-com.svg';
import {ReactComponent as UsersIcon} from '../../assets/icons/users-svgrepo-com.svg';
import {ReactComponent as CompanyIcon} from '../../assets/icons/company-svgrepo-com.svg';
import LoginImage from '../../assets/img/homepageImage.jpg';
import {ReactComponent as MailIcon} from '../../assets/icons/mail-svgrepo-com.svg';
import {AddShopUser, GetAllBusinessIndustry, GetAllBusinessSegment, GetEmployeeCount} from '../../api/utilities.service';
import {setAppLoading} from '../../redux/app/appSlice';
import {useDispatch, useSelector} from 'react-redux';
import {getItem} from '../../api/storage.service';
import {useAlertMessage} from '../../hooks/useAlertMessage';
import '../Login/Login.scss';
import {formToJSON} from 'axios';
import CustomRadioGroup from '../../components/reusable/radioGroup/RadioGroup';
import PageTitle from '../../components/reusable/pageTitle/PageTitle';
import {setUserInformation} from '../../redux/user/userSlice';
import {useQuery} from 'react-query';

const Signup = () => {
	let navigate = useNavigate();
	// const items = ['Individual', 'Business'];

	// const [selected, setSelected] = useState(items[0]);
	const dispatch = useDispatch();
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const userState = useSelector((state) => state.user);

	const employeeCountQuery = useQuery({
		retry: (count, err) => count < 3,
		queryKey: ['employeeCountQuery'],
		queryFn: () => GetEmployeeCount().then((res) => res?.data?.data),
	});

	const GetAllBusinessIndustryQuery = useQuery({
		retry: (count, err) => count < 3,
		queryKey: ['GetAllBusinessIndustryQuery'],
		queryFn: () => GetAllBusinessIndustry().then((res) => res?.data?.data),
	});

	const GetAllBusinessSegmentQuery = useQuery({
		retry: (count, err) => count < 3,
		queryKey: ['GetAllBusinessSegmentQuery'],
		queryFn: () => GetAllBusinessSegment().then((res) => res?.data?.data),
	});

	const sumbitHandler = (e) => {
		e.preventDefault();
		let payload = formToJSON(e.target);
		payload.msisdn = getItem('u_msisdn');

		payload.business = true;

		dispatch(setAppLoading(true));
		AddShopUser(payload)
			.then((res) => {
				dispatch(setAppLoading(false));
				if (res.data.success) {
					navigate(getItem('AfterVerificationPath'));
					const newUser = {...userState?.userInformation, userExists: true};
					dispatch(setUserInformation(newUser));
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
				dispatch(setAppLoading(false));
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	let SignupContent = (
		<div className=''>
			<div className='p-6 rounded-[20px] bg-white shadow-lg'>
				<h1 className='text-2xl text-center mb-5'>Create account</h1>
				<form onSubmit={sumbitHandler} className='space-y-3'>
					<div className=' flex flex-row items-center bg-[#f2f2f2] p-2.5 rounded-[10px] justify-start mb-6 gap-2'>
						<PhoneNumberInputIcon className='mr-[3px]' />
						<input type={'tel'} className='bg-transparent text-sm border-none outline-none w-full text-gray-500' name='msisdn' placeholder='Phone Number' disabled value={getItem('u_msisdn')} />
					</div>
					<div className='flex flex-row items-center bg-[#f2f2f2] p-2.5 rounded-[10px] justify-start mb-6 gap-2'>
						<PhoneNumberInputIcon className='mr-[3px]' />
						<input type={'text'} className='bg-transparent text-sm border-none outline-none w-full' name={`fullName`} placeholder={`Full Name`} required />
					</div>

					<div className='flex flex-row items-center bg-[#f2f2f2] p-2.5 rounded-[10px] justify-start mb-6 gap-2'>
						<PhoneNumberInputIcon className='mr-[3px]' />
						<input type={'text'} className='bg-transparent text-sm border-none outline-none w-full' name={`businessName`} placeholder={`Business Name`} required />
					</div>
					<div className='mb phone-number-input-container mb-6 gap-2'>
						<UsersIcon className='phone-number-input-icon w-5 h-5' />
						<select name='businessEmployeeCountId' className='!text-[14px] text-gray-400 font-bold bg-transparent outline-none w-full'>
							<option value=''>Select Number of employees</option>
							{employeeCountQuery?.data?.map((employee) => (
								<option key={employee?.businessEmployeeCountId} value={employee?.businessEmployeeCountId}>
									{employee?.businessEmployeeCount}
								</option>
							))}
						</select>
					</div>
					<div className='mb phone-number-input-container mb-6 gap-2'>
						<CompanyIcon className='phone-number-input-icon w-5 h-5' />
						<select name='businessIndustryId' className='!text-[14px] text-gray-400 font-bold bg-transparent outline-none w-full'>
							<option value=''>Select indudstry</option>
							{GetAllBusinessIndustryQuery?.data?.map((industry) => (
								<option key={industry?.businessIndustryId} value={industry?.businessIndustryId}>
									{industry?.businessIndustyName}
								</option>
							))}
						</select>
					</div>
					<input type='hidden' name='businessSegmentId' value={GetAllBusinessSegmentQuery?.data?.find((x) => x.businessSegmentName?.toLowerCase() === 'sme')?.businessSegmentId} />
					{/* <input type="hidden" name="businessSegmentId" value={}/> */}
					{/* <div className='mb phone-number-input-container mb-6 gap-2'>
								<PhoneNumberInputIcon className='phone-number-input-icon' />
								<select className='!text-[14px] text-gray-400 font-bold bg-transparent outline-none w-full'>
									<option value=''>Select indudstry</option>
									{GetAllBusinessSegment?.data?.map((industry) => (
										<option key={industry?.businessIndustryId} value={industry?.businessIndustryId}>
											{industry?.businessIndustyName}
										</option>
									))}
								</select>
							</div> */}

					<div className='flex flex-row items-center bg-[#f2f2f2] p-2.5 rounded-[10px] justify-start mb-6 gap-2'>
						<MailIcon className='mr-[3px]' />
						<input type={'email'} className='bg-transparent text-sm border-none outline-none w-full' name='email' placeholder='Email' required />
					</div>
					{/* 
					<div className='pt-5'>
						<CustomRadioGroup position={'horizontal'} items={items} selected={selected} setSelected={setSelected} />
					</div> */}

					<div className='pt-6'>
						<p className='text-[12px] flex  gap-1'>
							<input type='checkbox' className='accent-black' required />
							By signing up you agree to our
						</p>
						<Link to={'/'} className='font-semibold underline text-[12px] pl-4'>
							Terms & Conditions
						</Link>
					</div>

					<div className='centerItems'>
						<button type='submit' className='cursor-pointer w-full max-w-[500px] text-sm font-[bold] bg-[#ffca05] flex justify-center items-center mt-5 p-3 rounded-[26px] border-[none] hover:bg-[#ffc90571]'>
							Signup
						</button>
					</div>
				</form>
			</div>
		</div>
	);

	return (
		<>
			<PageTitle title='Business Hub - Sign up' />

			<div className='lg:flex min-h-screen'>
				<div className=' py-10 px-5 lg:w-[50%] lg:flex justify-center '>
					<div className=''>{employeeCountQuery.isLoading || GetAllBusinessIndustryQuery.isLoading || GetAllBusinessSegmentQuery.isLoading ? <div className='grid place-items-center h-[500px]'>Loading ...</div> : <div>{SignupContent}</div>}</div>
				</div>
				<div className='w-[50%] hidden lg:block lg:mt-0  '>
					<img src={LoginImage} alt='Login image' className='min-h-screen' />
				</div>
			</div>
		</>
	);
};

export default Signup;
