import NothingFound from '../../../assets/img/nothing-found.png'

export const NotFound = ({ ...prop }) => {

    return <>
        <div className="  text-center  h-max">
            <img className="mx-auto mt-5" src={NothingFound} />
            <div className="pt-5">{prop?.children}</div>
        </div>
    </>
}