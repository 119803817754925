import React, {useState} from 'react';
import {Modal} from '../modal/_component';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setAppLoading} from '../../../redux/app/appSlice';
import {useAlertMessage} from '../../../hooks/useAlertMessage';
import {AddTellMe} from '../../../api/utilities.service';
import { AiOutlineUndo } from 'react-icons/ai';

const NotifyMe = ({setShowModal, showModal, location}) => {
	const navigate = useNavigate();
	const [firstName, setFirstname] = useState('');
	const [lastName, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [contact, setContact] = useState('');
	const dispatch = useDispatch();
	let {showErrorMessage, showSuccessMessage} = useAlertMessage();
	const [loading, setLoading] = useState(false);

	const handleSubmit = () => {
		if (!firstName) return showErrorMessage('Please enter your first name', 'error');
		if (!lastName) return showErrorMessage('Please enter your last name', 'error');
		if (!email) return showErrorMessage('Please enter your email', 'error');
		if (!contact) return showErrorMessage('Please enter your contact', 'error');
		if (!location) return showErrorMessage('Please select your location', 'error');
		const data = {
			firstName,
			lastName,
			email,
			contact,
			location,
		};
		setLoading(true)
		AddTellMe(data)
			.then((res) => {
        setLoading(false)
				if (res.data.success) {
					showSuccessMessage('Your request has been sent succesfully!', 'success');
          setTimeout(() => {
            setShowModal(false)
            navigate('/')
          }, 3000)
				} else {
					showErrorMessage(res.data.message, 'error');
				}
			})
			.catch((err) => {
        setLoading(false)
				showErrorMessage('Sorry! An error occured ', 'error');
				console.log('Error Log:', err);
			});
	};

	return (
		<Modal open={showModal} close={() => setShowModal(false)} closeOnOverlay={true}>
			<div className='block p-4 rounded bg-white md:w-[500px] w-[90%] mx-auto space-y-4'>
				<h2 className='text-lg font-bold'>Tell me when this area is connected</h2>
				<input name='firstname' value={firstName} onChange={(e) => setFirstname(e.target.value)} type='text' className='text-[13px] w-full p-[15px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' placeholder='Enter First Name' />
				<input name='lastname' value={lastName} onChange={(e) => setLastname(e.target.value)}  type='text' className='text-[13px] w-full p-[15px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' placeholder='Enter Last Name' />
				<input name='contact' value={contact} onChange={(e) => setContact(e.target.value)}  type='number' className='text-[13px] w-full p-[15px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' placeholder='Enter Phone Number' />
				<input name='email' value={email} onChange={(e) => setEmail(e.target.value)}  type='text' className='text-[13px] w-full p-[15px] rounded-xl border-[none] bg-[#f2f2f2] outline-none' placeholder='Enter Email' />
				<button disabled={loading} type='button' onClick={() => handleSubmit()} className='rounded-full w-full p-3 bg-[#ffcc00] hover:bg-[#ffcc008c]'>
					{loading ? <AiOutlineUndo className='animate-spin mx-auto' />  : 'Submit'}
				</button>
			</div>
		</Modal>
	);
};

export default NotifyMe;
