import React, {useCallback, useRef, useState} from 'react';
import {AiOutlineClose, AiFillCamera, AiOutlineRetweet, AiOutlineRedo} from 'react-icons/ai';
import {useAlertMessage} from '../../../../hooks/useAlertMessage';
import {base64ToFile, uuid} from '../../../../utils';
import uploadIcon from '../../../../assets/icons/upload.png';
import {workflowUpload} from '../../../../api/upload.service';
import {Modal} from '../../modal/_component';
import Webcam from 'react-webcam';
import {BsUpload} from 'react-icons/bs';
import { Asterisk } from '../../asterisk/Asterisk';
import {useSelector} from 'react-redux';

const FileUploadFieldEntry = ({data, index}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [fileNames, setFileNames] = useState([]);
	const separator = ';;';
	const {showSuccessMessage, showErrorMessage} = useAlertMessage();
	const [showModal, setShowModal] = useState(false);
	const webcamRef = useRef(null);
	const [imgSrc, setImgSrc] = useState(null);
	const [frontCam, setFrontCam] = useState(true);
	const isFieldRequired = data?.required;
	const fieldErrorMessages = useSelector((state) => state.product.fieldErrorMessages);
	const hasErrorMessage = fieldErrorMessages.some((item) => item.id === data?.id);

	const isExtensionAllowed = (url, allowedExtension) => {
		const re = /(?:\.([^.]+))?$/;
		const ext = re.exec(url)[1]?.toLowerCase();
		return allowedExtension.includes(ext);
	};

	const allowedExtensions = () => data?.options?.find((x) => x.key === 'EXTENSIONS')?.value?.split(separator) || [];
	const allowedExtensionsRaw = () => data?.options?.find((x) => x.key === 'EXTENSIONS')?.value || '';
	const extensionMessage = () => data?.options?.find((x) => x.key === 'EXTENSIONS-ERROR-MESSAGE')?.value || 'Invalid file extension.';
	const maxFileSizeMessage = () => data?.options?.find((x) => x.key === 'MAX-FILE-SIZE-MB-MESSAGE')?.value || 'File size exceeds the limit.';
	const allowedFileSizeMb = () => data?.options?.find((x) => x.key === 'MAX-FILE-SIZE-MB')?.value || '3';
	const maxFiles = () => data?.options?.find((x) => x.key === 'MAX')?.value || '1';
	const maxFilesMessage = () => data?.options?.find((x) => x.key === 'MAX-MESSAGE')?.value || 'You have reached the maximum number of files allowed.';
	const enableLiveCapture = data?.options?.find((x) => x.key === 'ENABLE-LIVE-CAPTURE')?.value === 'true';

	const uploadSelectedFiles = (formData, uid) => {
		return new Promise((resolve, reject) => {
			workflowUpload(formData)
				.then((response) => {
					resolve({success: true, uid, path: response.data.filePath});
					const newFileNames = [...fileNames, response?.data?.newFileName];
					setFileNames(newFileNames);
					showSuccessMessage('File uploaded successfully', 'success');
					setImgSrc(null)
					setShowModal(false)
				})
				.catch((err) => {
					console.error('Upload error:', err); // Log the error
					removeFile(uid);
					showErrorMessage('Upload Failed!', 'error');
					reject(err);
				});
		});
	};

	const handleUploadRequest = (type) => {
		let filesArray;
		setIsSubmitting(true);
		const inpFile = document.getElementById('_' + data?.id);

		if (type === 'live') {
			const convertedFile = base64ToFile(imgSrc, 'live_capture.jpg');
			filesArray = [convertedFile];
		} else {
			filesArray = Array.from(inpFile.files);
		}

		if (filesArray.length > Number(maxFiles())) {
			showErrorMessage(maxFilesMessage(), 'error');
			setIsSubmitting(false);
			return;
		}

		const allowedFiles = filesArray.filter((x) => isExtensionAllowed(x.name, allowedExtensions()));
		const unacceptedFiles = filesArray.filter((x) => !isExtensionAllowed(x.name, allowedExtensions()));
		const isSizeAllowed = (file) => file.size <= Number(allowedFileSizeMb()) * 1048576;

		if (unacceptedFiles.length > 0) {
			showErrorMessage(extensionMessage(), 'error');
			setIsSubmitting(false);
			return;
		}

		let filesSizesNotAllowed = [];
		const uploads = allowedFiles
			.filter((x) => {
				const sizeAllowed = isSizeAllowed(x);
				if (!sizeAllowed) filesSizesNotAllowed.push(x.name);
				return sizeAllowed;
			})
			.map((x) => ({
				uid: uuid(),
				name: x.name,
				content: x,
				loading: true,
			}));

		if (filesSizesNotAllowed.length > 0) {
			showErrorMessage(maxFileSizeMessage() + '\r\n\r\nFiles:\n' + filesSizesNotAllowed.join('\n'), 'error');
		}

		const newFiles = [...uploadedFiles, ...uploads];
		setUploadedFiles(newFiles);

		uploads.forEach((file) => {
			const formData = new FormData();
			formData.append('file', file.content);
			uploadSelectedFiles(formData, file.uid)
				.then((res) => updateUploadedFilesState(res, file, newFiles))
				.catch((err) => showErrorMessage('Upload failed', 'error'))
				.finally(() => setIsSubmitting(false));
		});
	};

	const updateUploadedFilesState = (res, file, newFiles) => {
		if (res.success) {
			const updatedFiles = newFiles.map((f) => (f.uid === file.uid ? {...f, loading: false, path: res.path} : f));
			setUploadedFiles(updatedFiles);
		}
	};

	const removeFile = (uid) => {
		setUploadedFiles(uploadedFiles.filter((x) => x.uid !== uid));
	};

	const capture = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		setImgSrc(imageSrc);
	}, [webcamRef, setImgSrc]);

	const handleLiveCapture = () => {
		setImgSrc(null);
		setShowModal(true);
	};

	// const handleCaptureAndUpload = () => {
	// 	const convertedFile = base64ToFile(imgSrc, 'live_capture.jpg');
	// 	const formData = new FormData();
	// 	formData.append('file', convertedFile);
	// 	uploadSelectedFiles(formData, file.uid)
	// 		.then((res) => updateUploadedFilesState(res, file, newFiles))
	// 		.catch((err) => showErrorMessage('Upload failed', 'error'))
	// 		.finally(() => setIsSubmitting(false));
	// };

	return (
		<div className='py-8'>
			{data?.options.find((x) => x.key === 'SHOW_DISPLAY_NAME')?.value === 'true' && <small className='text-[13px]'>{data?.displayName} {isFieldRequired && <Asterisk />}</small>}

			<div className='mt-3'>
				<input
					id={'_' + data?.id}
					type='file'
					accept={allowedExtensions()
						.map((ext) => `.${ext}`)
						.join(', ')}
					onChange={() => handleUploadRequest('library')}
					className='hidden'
					multiple={Number(maxFiles()) > 1}
				/>

				<div className='flex items-center'>
					<div onClick={() => document.getElementById('_' + data?.id).click()} className='flex items-center bg-gray-100 rounded-full px-4 py-2 min-w-[200px] cursor-pointer'>
						{uploadedFiles.length > 0 ? (
							<div className='flex flex-wrap gap-2'>
								{uploadedFiles.map(({uid, name}, idx) => (
									<div key={idx} className='flex items-center'>
										<span className='text-xs truncate'>{name}</span>
										<button onClick={() => removeFile(uid)} className='mx-2 p-[1px] rounded-full border border-gray-600'>
											<AiOutlineClose size={8} className='text-gray-600 ' />
										</button>
									</div>
								))}
							</div>
						) : (
							<span className='text-xs text-gray-400 text-center'> Upload Photo</span>
						)}
					</div>

					{/* {uploadedFiles.length < Number(maxFiles()) && (
						<label onClick={handleLiveCapture} className='ml-2 bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center cursor-pointer'>
							<img src={uploadIcon} className='' alt='upload' />
						</label>
					)} */}
					{enableLiveCapture && (
						<label onClick={handleLiveCapture} className='ml-2 bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center cursor-pointer'>
							<img src={uploadIcon} className='' alt='upload' />
						</label>
					)}
				</div>

				<div className='mt-2 text-xs text-gray-500'>
					<span>{`Allowed File Types - ${allowedExtensions().join(', ')} `}</span>
					<span>{`(${allowedFileSizeMb()}MB Max)`}</span>
				</div>
			</div>
			<p className='text-red-600 text-[12px] mb-5'>{hasErrorMessage && `${data?.displayName} is required`}</p>


			<Modal open={showModal} close={setShowModal} closeOnOverlay={true}>
				<div className='block py-0 px-0 rounded bg-white min-w-[400px]'>
					{/* <div className=''> */}
					<>
						{imgSrc ? (
							<img src={imgSrc} />
						) : (
							showModal && (
								<Webcam
									audio={false}
									width={700}
									videoConstraints={{
										facingMode: frontCam ? 'user' : 'environment',
									}}
									height={700}
									mirrored
									ref={webcamRef}
									screenshotFormat='image/jpeg'
								/>
							)
						)}

						<div className='grid place-items-center'>
							{imgSrc ? (
								<div className='flex justify-center gap-2'>
									<button type='button' className='bg-black flex items-center gap-2 text-white py-2 my-4 px-4 text-[13px]' onClick={() => handleUploadRequest('live')}>
										Save and upload
										<span>
											<BsUpload />
										</span>
									</button>
									<button type='button' className='bg-black flex items-center gap-2 text-white py-2 my-4 px-4 text-[13px]' onClick={() => setImgSrc(null)}>
										Retake
										<span>
											<AiOutlineRedo />
										</span>
									</button>
								</div>
							) : (
								<div className='flex justify-center gap-2'>
									<button type='button' className='bg-black flex items-center gap-2 text-white py-2 my-4 px-4 text-[13px]' onClick={() => setFrontCam(!frontCam)}>
										Switch camera
										<span>
											<AiOutlineRetweet />
										</span>
									</button>
									<button type='button' className='bg-black flex items-center gap-2 text-white py-2 my-4 px-4 text-[13px]' onClick={capture}>
										Capture photo
										<span>
											<AiFillCamera />
										</span>
									</button>
								</div>
							)}
						</div>
					</>
				</div>
				{/* </div> */}
			</Modal>

			<input type='hidden' name={`response[${index}][value]`} value={fileNames} />
			<input type='hidden' name={`response[${index}][meta]`} />
			<input type='hidden' name={`response[${index}][controlName]`} value={data?.displayName} />
			<input type='hidden' name={`response[${index}][componentId]`} value={data?.componentId} />
			<input type='hidden' name={`response[${index}][id]`} value={data?.id} />
		</div>
	);
};

export default FileUploadFieldEntry;
