import React from 'react';
import './orders.scss';
import tnet from '../../assets/img/svgs/tnet-img.svg';
import {useNavigate} from 'react-router-dom';
import moment from 'moment/moment';
import SMEImg from '../../assets/img/SMEProductImg.jpg';
import {storeItem} from '../../api/storage.service';

function OrdersCard({order}) {
	const navigate = useNavigate();
	const productCount = () => Number(order?.productCount);

	return (
		<>
			<div className='w-full  max-w-[400px] bg-[#e3e3e3] box-border shadow-[-4px_4px_28px_-1px_rgba(0,0,0,0.19)]  mb-[45px] m-auto pb-0 px-[13px] rounded-[30px]'>
				<div className='p-2.5'>
					<div className=''>
						<span className='font-bold'>{`ORDER #${order?.orderNumber}`}</span>
						<br />
						<span className='text-xs uppercase'>{moment(order?.cartDate).format('Do MMMM YYYY')}</span>
					</div>
					<div className='float-right -mt-10'>
						<button className='text-center rounded-[10px] bg-[#ffca05] text-[11px] py-[5px] px-2.5 h-auto'>{order?.orderStatus}</button>
					</div>
				</div>
				<div className='bg-white ml-[-15px] mr-[-15px] p-4 text-black rounded-[30px]'>
					<div className='w-[100px] h-[100px] bg-[#f5f5f5] float-left  max-w-[122px] rounded-[30px] '>
						<img src={SMEImg} className='rounded-2xl' />
					</div>
					<div className='pl-[120px] mb-[50px]'>
						<div className='text-[#898989]'>{order?.productDetails[0]?.productName}</div>
						<div>
							<span className='text-sm'>{order?.productDetails[0]?.packageName}</span>
						</div>
						<div className='font-bold'>{Number(order?.productDetails[0]?.packageTotal)?.toFixed(2)}</div>
						{productCount() >= 2 ? (
							<div className='text-xs mt-2'>
								and <b className='font-bold'>{productCount() - 1}</b> other product(s)
							</div>
						) : (
							''
						)}
					</div>
					<div className='flex justify-center'>
						{order?.orderStatus.toLowerCase() === 'not paid' ? (
							<button
								onClick={() => {
									storeItem('orderID', order?.orderId);
									navigate('/choose-payment');
								}}
								className='bg-white border cursor-pointer text-xs p-2.5 rounded-[15px] border-solid border-black hover:bg-[#ffcc00] hover:border-[#ffcc00] mr-2'
							>
								Pay Now
							</button>
						) : (
							<></>
						)}

						<button
							onClick={() => {
								navigate(`/order-details/${order?.orderId}`);
							}}
							className='bg-white border cursor-pointer text-xs p-2.5 rounded-[15px] border-solid border-black hover:bg-[#ffcc00] hover:border-[#ffcc00]'
						>
							View/Track Order
						</button>
						<button className='bg-white text-xs h-1/5 ml-2.5 px-3 py-2 rounded-[10px] border-2 border-solid border-[#e9e9e9]'>{`x${order?.totalQuantity}`}</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default OrdersCard;
