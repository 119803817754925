import React, {useState} from 'react';
import {useAlertMessage} from '../../../../../hooks/useAlertMessage';
import { Asterisk } from '../../../asterisk/Asterisk';

const IndividualComponent = ({setIndividualPhoneNumber}) => {
	let [inputError, setInputError] = useState(false);
	let {showSuccessMessage, showErrorMessage} = useAlertMessage();

	const validateMsisdn = (e) => {
		const value = e.target.value;
		if (value.length < 10) {
			showErrorMessage('Input must have at least 10 digits!', 'error');
			document.getElementById('dyb_customer_number').value = '';
			setIndividualPhoneNumber("")
			setInputError(true);
		}
	};

	return (
		<>
			<div>
				<label className='text-xs uppercase text-gray-400'>recipient phone number <Asterisk/></label>
				<input
					id='dyb_customer_number'
					type='number'
					onBlur={validateMsisdn}
					className={`${inputError ? 'border border-red-600' : ''} text-[13px] w-full my-[10px] p-[17px] rounded-xl border-[none] bg-[#f2f2f2] outline-none`}
					name={`individual`}
					placeholder='Phone number'
					onChange={(e) => setIndividualPhoneNumber(e.target.value)}
				/>
			</div>
		</>
	);
};

export default IndividualComponent;
