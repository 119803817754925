import {getAxios, getConfig} from '../utils';

const apiAxios = () => getAxios(getConfig().apiBaseUrl.ordersBaseUrl);
// const apiAxios = () => getAxios('http://localhost:5000/api');

export const GetPaymentSessionDetails = async (payload) => await apiAxios().post('/Payment/GenerateCardPaymentSession', {...payload});
export const UpdatePaymentStatus = async (payload) => await apiAxios().post('/Payment/UpdatePaymentStatus', {...payload});
export const TriggerPayViaMOMO = async (payload) => await apiAxios().post('/Payment/PayViaMOMO', {...payload});
export const CheckMomoPaymentStatus = async (payload) => await apiAxios().post('/Payment/VerifyPayment', {...payload});

export const PayViaMomoExternal = async (payload) => await apiAxios().post('/Payment/PayViaMomoExternal', {...payload});
export const VerifyPaymentExternal = async (payload) => await apiAxios().post('/Payment/VerifyPaymentExternal', {...payload});
