import React from 'react';
import {useNavigate} from 'react-router-dom';
import './ReturnButton.scss';

const ReturnButton = () => {
	const navigate = useNavigate();

	const handleNavigation = () => {
		navigate(-1);
	};

	return (
		<button onClick={handleNavigation} className='bg-[#ffca05] font-bold text-sm px-1.5 py-1 rounded-sm border-[none] hover:bg-[#ffc9059c]'>
			BACK
		</button>
	);
};

export default ReturnButton;
